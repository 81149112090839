import styles from "./addOrEditQuestionnaire.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  periodOptions,
  questionnaireTypes,
  statusOptions,
} from "../../../utils/constants";
import DatePicker, { DateObject } from "react-multi-date-picker";
import {
  TAddNewQuestionnaireForm,
  TLabeledItem,
  TQuestionnaireTypeOption,
} from "../../../types/types";
import React, { ChangeEvent, FormEvent } from "react";

interface IAddOrEditQuestionnaire {
  form: Partial<TAddNewQuestionnaireForm>;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectChange: (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => void;
  isLoading: boolean;
  onSubmit: (e: FormEvent) => void;
  onDateChange: (nv: DateObject) => void;
}
function AddOrEditQuestionnaireForm({
  form,
  handleChange,
  onSelectChange,
  onDateChange,
}: IAddOrEditQuestionnaire) {
  console.log(form);
  return (
    <form className={styles.form}>
      <ul className={styles.form__list}>
        <li>
          <label htmlFor="Desc" className={styles.form__label}>
            שם שאלון
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Desc"
            id="Desc"
            value={form.Desc}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="Symbol" className={styles.form__label}>
            סימן
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Symbol"
            id="Symbol"
            value={form.Symbol}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="StartQuestion" className={styles.form__label}>
            שאלה ראשונית
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="StartQuestion"
            id="StartQuestion"
            value={form.StartQuestion}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="EndQuestion" className={styles.form__label}>
            משפט סיום
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="EndQuestion"
            id="EndQuestion"
            value={form.EndQuestion}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="type" className={styles.form__label}>
            סוג שאלון
          </label>
          <Select
            name={"type"}
            options={questionnaireTypes}
            placeholder={"אנא בחרסוג שאלון"}
            defaultValue={questionnaireTypes[0]}
            value={
              form.type
                ? questionnaireTypes.find((t) => t.Id === Number(form.type))
                : undefined
            }
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="StatusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"StatusId"}
            options={statusOptions}
            value={
              form.StatusId
                ? statusOptions.find((o) => o.value === form.StatusId)
                : undefined
            }
            placeholder={"אנא בחרסוג סטטוס"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        {(form.type === 1 || form.type === "1") && (
          <li>
            <label htmlFor="periodicity" className={styles.form__label}>
              {form.default ? "דפולט" : "תקופתיות"}
            </label>
            <Select
              name={"periodicity"}
              options={periodOptions}
              value={
                form.periodicity
                  ? periodOptions.find(
                      (o) => o.value === form.periodicity?.value
                    )
                  : form.default
                  ? periodOptions.find((o) => o.value === "Default")
                  : undefined
              }
              placeholder={"אנא בחרסוג תקופתיות"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
        )}
        {(form.type === 1 || form.type === "1") && form.periodicity && (
          <li>
            <label htmlFor="additional" className={styles.form__label}>
              {form.periodicity.value === "Dayly"
                ? "בחר תאריך"
                : form.periodicity.value === "Monthly"
                ? "בחר חודש"
                : "ערך דפולטיבי"}
            </label>
            {form.periodicity.value === "Default" ? (
              <input
                type={"text"}
                className={`${styles.form__input}`}
                name="default"
                id="default"
                value={form.default}
                onChange={handleChange}
              />
            ) : (
              <div className={`${styles.form__datePicker}`}>
                <DatePicker
                  value={
                    form.periodicity.value === "Monthly"
                      ? form.monthi &&
                        new Date().setMonth(Number(form.monthi) - 1)
                      : form.Date
                  }
                  onChange={(nv: DateObject) => onDateChange(nv)}
                  onlyMonthPicker={form.periodicity.value === "Monthly"}
                  format={
                    form.periodicity.value === "Monthly" ? "MMMM" : "YYYY-MM-DD"
                  }
                />
              </div>
            )}
          </li>
        )}
      </ul>
    </form>
  );
}

export default AddOrEditQuestionnaireForm;
