import {
  IQuestion,
  IQuestionnaireData,
  TCategory,
  TExercise,
  TFeature,
  TQuestionOption,
  TQuestionsSortedByQuestiorraireResponse,
  TUtilsState,
} from "../types/utils.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { utilsApi } from "../api/utils.api";

const initialState: TUtilsState = {
  questionnaires: [],
  questions: [],
  questionsSortedByQuestionnaire: {},
  questionOptions: undefined,
  features: [],
  exercises: [],
  libraryCategories: [],
  error: "",
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        utilsApi.endpoints.getQuestionnaires.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return { ...state, questionnaires: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.searchQuestionnaire.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return { ...state, questionnaires: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.searchQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return { ...state, questions: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return { ...state, questions: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return { ...state, questionOptions: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getFeatures.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TFeature[]>) => {
          return { ...state, features: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.searchFeatures.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TFeature[]>) => {
          return { ...state, features: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionsSortedByQuestionnaire.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TQuestionsSortedByQuestiorraireResponse>
        ) => {
          return { ...state, questionsSortedByQuestionnaire: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getExercises.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercise[]>) => {
          return { ...state, exercises: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.searchLibrary.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercise[]>) => {
          return { ...state, exercises: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getLibraryCategories.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TCategory[]>) => {
          return { ...state, libraryCategories: action.payload };
        }
      );
  },
});

export const { actions, reducer } = utilsSlice;
export default utilsSlice.reducer;
