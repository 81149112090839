import styles from "../forms.module.css";
import { TAddFeatureRequest } from "../../../services/types/utils.types";
import React, { ChangeEvent, FormEvent } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../loader/loader";

interface IAddOrEditFeatureFormProps {
  form: TAddFeatureRequest;
  isLoading: boolean;
  onSubmit: (e: FormEvent) => void;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
function AddOrEditFeatureForm({
  form,
  isLoading,
  onSubmit,
  handleChange,
}: IAddOrEditFeatureFormProps) {
  const { id } = useParams();
  console.log(form);
  return isLoading ? (
    <Loader />
  ) : (
    <form className={styles.form}>
      <ul
        className={`${styles.form__list} ${styles.form__list_type_threeColumns}`}
      >
        <li>
          <label htmlFor="Name" className={styles.form__label}>
            שם הפיצ'ר
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Name"
            id="Name"
            value={form.Name}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="Symbol" className={styles.form__label}>
            סימן
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Symbol"
            id="Symbol"
            value={form.Symbol}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="number" className={styles.form__label}>
            מספר
          </label>
          <input
            type="number"
            className={`${styles.form__input}`}
            name="number"
            id="number"
            value={form.number}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          !form.Name && styles.form__btn_disabled
        } `}
        disabled={!form.Name}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : id ? "לערוך פיצ'ר" : "ליצור פיצ'ר"}
      </button>
    </form>
  );
}

export default AddOrEditFeatureForm;
