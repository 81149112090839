import styles from "./addNewQuestion.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { addNewQuestionInitialState } from "../../utils/constants";
import AddOrEditQuestionForm from "../../components/forms/addOrEditQuestion/addOrEditQuestionForm";
import { ActionMeta, SingleValue } from "react-select";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../types/types";
import { useAddQuestionMutation } from "../../services/api/utils.api";
import { TQuestionOption } from "../../services/types/utils.types";

function AddNewQuestionPage() {
  const navigate = useNavigate();
  const [form, setForm] = useState(addNewQuestionInitialState);
  const [options, setOptions] = useState<TQuestionOption[]>([]);

  const [addQuestion] = useAddQuestionMutation();

  useEffect(() => {});
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    setForm((prev) => ({ ...prev, [name as string]: value?.value }));
    if (name === "DescQuestionnaire") {
      value && setForm((prev) => ({ ...prev, NameQ: value.value }));
    }
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>הוספת שאלה חדשה</h1>
      <AddOrEditQuestionForm
        form={form}
        handleChange={handleChange}
        onSelectChange={onSelectChange}
        isLoading={false}
        questionOptions={options}
        onSubmit={(e) => {
          e.preventDefault();
          addQuestion(form);
          navigate(-1);
        }}
      />
    </section>
  );
}

export default AddNewQuestionPage;
