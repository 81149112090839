import styles from "./features.module.css";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { searchFeaturesOptions } from "../../utils/constants";
import {
  useDeleteFeatureMutation,
  useGetFeaturesQuery,
  useLazyGetFeaturesQuery,
  useSearchFeaturesMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { featuresSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import { TLabeledItem } from "../../types/types";
import { useDebounce } from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import useMediaQuery from "../../hooks/useMediaQuery";

function FeaturesPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const [selectValue, setSelectValue] = useState<TLabeledItem>(
    searchFeaturesOptions[0]
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [featureToDeleteId, setFeatureToDeleteId] = useState("");

  const { isLoading } = useGetFeaturesQuery();

  const features = useAppSelector(featuresSelector);
  const [searchFeature, { isLoading: isSearchLoading }] =
    useSearchFeaturesMutation();
  const [deleteFeature, { isLoading: isDeleteLoading }] =
    useDeleteFeatureMutation();
  const [refechFeatures, { isLoading: isRefechLoading }] =
    useLazyGetFeaturesQuery();
  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    console.log(isDeleteLoading);
  }, [isDeleteLoading]);
  const search = useCallback(async () => {
    searchFeature({
      val: debouncedValue,
      ClumnSerch: selectValue.value,
    });
  }, [debouncedValue, searchFeature, selectValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const onSelectChange = (value: SingleValue<TLabeledItem>) => {
    setSelectValue(value as TLabeledItem);
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <section className={styles.features}>
      <div className={styles.header}>
        <button
          type={"button"}
          className={styles.addBtn}
          onClick={() => navigate("add_new")}
        >
          {!mobile && "הוסף הפיצ'ר חדש"}
          <span className={styles.plus}>+</span>
        </button>
        <input
          type={"text"}
          placeholder={`חפס...`}
          className={styles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
        <Select
          name={"search"}
          options={searchFeaturesOptions}
          placeholder={"אנא בחר..."}
          className={styles.select}
          defaultValue={searchFeaturesOptions[0]}
          onChange={onSelectChange}
        />
      </div>
      {isLoading || isSearchLoading ? (
        <Loader />
      ) : features.length > 0 ? (
        <ul className={styles.list}>
          <li className={`${styles.listRow} ${styles.listRow_header}`}>
            <p className={styles.listCell}>מס`(id):</p>
            <p className={styles.listCell}>שם:</p>
            <p className={styles.listCell}>סימן:</p>
            <p className={styles.listCell}>מספר:</p>
            <p className={styles.listCell}>אפשרויות:</p>
          </li>
          {features.map((f, index) => (
            <li
              key={f.Id}
              className={`${styles.listRow} ${
                index % 2 === 0 && styles.listRow_colored
              }`}
            >
              <p className={styles.listCell}>{f.Id}</p>
              <p className={styles.listCell}>{f.Desc}</p>
              <p className={styles.listCell}>{f.Symbol}</p>
              <p className={styles.listCell}>{f.Number}</p>
              <div className={styles.listCell}>
                <button
                  type={"button"}
                  className={`${styles.btn} ${styles.btn_type_edit}`}
                  onClick={() => navigate(`/protocols/features/edit/${f.Id}`)}
                  disabled={isDeleteLoading || isRefechLoading || isLoading}
                />
                <button
                  type={"button"}
                  className={`${styles.btn} ${styles.btn_type_delete}`}
                  onClick={() => {
                    setFeatureToDeleteId(f.Id.toString());
                    setOpenModal(true);
                  }}
                  disabled={isDeleteLoading || isRefechLoading || isLoading}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.text}>לא נמצאו תוצאות</p>
      )}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onDelete={() => {
              deleteFeature(featureToDeleteId).then(() => refechFeatures());
            }}
            onClose={() => setOpenModal(false)}
          />
        </Modal>
      )}
    </section>
  );
}

export default FeaturesPage;
