import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/login/login";
import LoginSmsCode from "../pages/login/loginSmsCode";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import MainContentLayout from "../components/mainContentLayout/mainContentLayout";
import { Category } from "../types/types";
import UsersPage from "../pages/users/usersPage";
import LibraryPage from "../pages/library/library";
import HomePage from "../pages/home/home";
import ProtocolsPage from "../pages/protocols/protocols";
import QuestionnairesPage from "../pages/questionnaires/questionnaires";
import AddNewQuestionnairePage from "../pages/addNewQuestionnaire/addNewQuestionnaire";
import EditQuestionnairePage from "../pages/editQuestionnaire/editQuestionnaire";
import QuestionsPage from "../pages/questions/questions";
import AddNewQuestionPage from "../pages/addNewQuestion/addNewQuestion";
import EditQuestionPage from "../pages/editQuestion/editQuestion";
import FeaturesPage from "../pages/features/features";
import AddNewFeaturePage from "../pages/addOrEditFeature/addNewFeature";
import EditFeaturePage from "../pages/addOrEditFeature/editFeature";
import AddNewExercisePage from "../pages/addNewExercise/addNewExercise";
import EditDataPage from "../pages/editData/editDataPage";
import QuestionReportsPage from "../pages/questionReports/questionReportsPage";
import ActionReportPage from "../pages/actionReportPage/actionReportPage";

export const useRoutes = (
  isAuth: boolean,
  changeCategory: (
    e: React.MouseEvent<Element, MouseEvent>,
    cat: Category
  ) => void
) => (
  <Routes>
    <Route path={"/login"} element={<LoginPage />} />
    <Route path={"login/enter_code"} element={<LoginSmsCode />} />
    <Route
      path={"/"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Main}
            changeCategory={changeCategory}
          >
            <HomePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <ProtocolsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questionnaires"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <QuestionnairesPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questionnaires/add_new"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <AddNewQuestionnairePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questionnaires/edit/:id"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <EditQuestionnairePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questions"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <QuestionsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questions/add_new"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <AddNewQuestionPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/questions/edit/:id"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <EditQuestionPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/features"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <FeaturesPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/features/add_new"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <AddNewFeaturePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />{" "}
    <Route
      path={"/protocols/features/edit/:id"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <EditFeaturePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/library"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <LibraryPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/protocols/library/add_new"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Protocols}
            changeCategory={changeCategory}
          >
            <AddNewExercisePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <UsersPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/actions"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <EditDataPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/questions_reports"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <QuestionReportsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/report/:cycle?/:questionnaire?"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <ActionReportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
  </Routes>
);
