import { api } from "./api";
import { protocolsUrl } from "../../utils/constants";
import {
  IQuestion,
  IQuestionnaireData,
  TAddExerciseRequest,
  TAddFeatureRequest,
  TAddNextQuestionIdToAllQuestionOptionsRequest,
  TAddNextQuestionIdToOption,
  TAddQuestionRequest,
  TCategory,
  TCreateQuestionnaireRequest,
  TDataType,
  TEditFeatureRequest,
  TEditQuestionnaireRequest,
  TExercise,
  TFeature,
  TQuestionOption,
  TQuestionsSortedByQuestiorraireResponse,
  TSearchFeaturesRequest,
  TSearchQuestionnaireRequest,
} from "../types/utils.types";

export const utilsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionnaires: build.query<IQuestionnaireData[], { skip: boolean }>({
      query: () => ({
        url: protocolsUrl,
        method: "GET",
      }),
    }),
    searchQuestionnaire: build.mutation<
      IQuestionnaireData[],
      TSearchQuestionnaireRequest
    >({
      query: (body: TSearchQuestionnaireRequest) => ({
        url: `${protocolsUrl}/SerchQushinnere`,
        method: "POST",
        body,
      }),
    }),
    searchQuestions: build.mutation<IQuestion[], TSearchQuestionnaireRequest>({
      query: (body: TSearchQuestionnaireRequest) => ({
        url: `${protocolsUrl}/Serch`,
        method: "POST",
        body,
      }),
    }),
    searchFeatures: build.mutation<TFeature[], TSearchFeaturesRequest>({
      query: (body: TSearchFeaturesRequest) => ({
        url: `${protocolsUrl}/serchFeach`,
        method: "POST",
        body,
      }),
    }),
    deleteQuestionnaire: build.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${protocolsUrl}/Delquen/${id}`,
        method: "DELETE",
      }),
    }),
    deleteQuestion: build.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${protocolsUrl}/DeleteQustions/${id}`,
        method: "DELETE",
      }),
    }),
    createQuestionnaire: build.mutation<boolean, TCreateQuestionnaireRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/newequen`,
        method: "POST",
        body,
      }),
    }),
    editQuestionnaire: build.mutation<boolean, TEditQuestionnaireRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/EditOfquen`,
        method: "POST",
        body,
      }),
    }),
    addQuestion: build.mutation<boolean, TAddQuestionRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/AddQuestin`,
        method: "POST",
        body,
      }),
    }),
    editQuestion: build.mutation<boolean, IQuestion>({
      query: (body) => ({
        url: `${protocolsUrl}/Updata`,
        method: "POST",
        body,
      }),
    }),
    getQuestions: build.query<IQuestion[], void>({
      query: () => ({
        url: `${protocolsUrl}/GetQuestions`,
        method: "GET",
      }),
    }),
    getQuestionsData: build.query<TDataType, void>({
      query: () => ({
        url: `${protocolsUrl}/GetData`,
        method: "GET",
      }),
    }),
    getQuestionOptions: build.query<TQuestionOption[], number>({
      query: (id) => ({
        url: `${protocolsUrl}/GetOption/${id}`,
        method: "GET",
      }),
    }),
    addOption: build.mutation<boolean, { id: string; text: string }>({
      query: (body) => ({
        url: `${protocolsUrl}/AddAnswer`,
        method: "POST",
        body,
      }),
    }),
    updateOption: build.mutation<boolean, { id: string; text: string }>({
      query: (body) => ({
        url: `${protocolsUrl}/UpdateOP`,
        method: "PUT",
        body,
      }),
    }),
    deleteOption: build.mutation<boolean, number>({
      query: (id) => ({
        url: `${protocolsUrl}/DeleteAnswer/${id}`,
        method: "DELETE",
      }),
    }),
    addNextQuestionIdToAllQuestionOptions: build.mutation<
      boolean,
      TAddNextQuestionIdToAllQuestionOptionsRequest
    >({
      query: (body) => ({
        url: `${protocolsUrl}/addNewQustionsIdSAndOption`,
        method: "POST",
        body,
      }),
    }),
    updateNextQuestionIdToOptionsId: build.mutation<boolean, string>({
      query: (id) => ({
        url: `${protocolsUrl}/UpNextQuestion`,
        method: "POST",
        body: { id, val: "לפי האופציה" },
      }),
    }),
    addNextQuestionIdToOption: build.mutation<
      boolean,
      TAddNextQuestionIdToOption
    >({
      query: (body) => ({
        url: `${protocolsUrl}/addNewQustionsIdSAndOption`,
        method: "POST",
        body,
      }),
    }),
    getFeatures: build.query<TFeature[], void>({
      query: () => ({
        url: `${protocolsUrl}/GetFeatures`,
        method: "GET",
      }),
    }),
    addFeature: build.mutation<TFeature[], TAddFeatureRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/Addfeacher`,
        method: "POST",
        body,
      }),
    }),
    deleteFeature: build.mutation<boolean, string>({
      query: (id) => ({
        url: `${protocolsUrl}/DeleteFeacher/${id}`,
        method: "DELETE",
      }),
    }),
    editFeature: build.mutation<boolean, TEditFeatureRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/UpFeacher`,
        method: "POST",
        body,
      }),
    }),
    getQuestionsSortedByQuestionnaire: build.query<
      TQuestionsSortedByQuestiorraireResponse,
      void
    >({
      query: () => ({
        url: `${protocolsUrl}/GetallQuestions`,
        method: "GET",
      }),
    }),
    getLibraryCategories: build.query<TCategory[], void>({
      query: () => ({
        url: `${protocolsUrl}/GetCategiz`,
        method: "GET",
      }),
    }),
    getExercises: build.query<TExercise[], void>({
      query: () => ({
        url: `${protocolsUrl}/GetExercises`,
        method: "GET",
      }),
    }),
    addExercise: build.mutation<boolean, TAddExerciseRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/Addfeacher`,
        method: "POST",
        body,
      }),
    }),
    deleteExercise: build.mutation<boolean, string>({
      query: (id) => ({
        url: `${protocolsUrl}/`,
        method: "DELETE",
      }),
    }),
    editExercise: build.mutation<boolean, TEditFeatureRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/AddNewExires`,
        method: "POST",
        body,
      }),
    }),
    searchLibrary: build.mutation<TExercise[], TSearchFeaturesRequest>({
      query: (body) => ({
        url: `${protocolsUrl}/serchExercises`,
        method: "POST",
        body,
      }),
    }),
    addCategory: build.mutation<boolean, string>({
      query: (val) => ({
        url: `${protocolsUrl}/AddCategory`,
        method: "POST",
        body: { val },
      }),
    }),
  }),
});

export const {
  useGetQuestionnairesQuery,
  useLazyGetQuestionnairesQuery,
  useSearchQuestionnaireMutation,
  useSearchQuestionsMutation,
  useSearchFeaturesMutation,
  useCreateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useDeleteQuestionMutation,
  useEditQuestionnaireMutation,
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useGetQuestionsDataQuery,
  useAddQuestionMutation,
  useEditQuestionMutation,
  useLazyGetQuestionOptionsQuery,
  useAddNextQuestionIdToAllQuestionOptionsMutation,
  useAddOptionMutation,
  useDeleteOptionMutation,
  useAddNextQuestionIdToOptionMutation,
  useUpdateOptionMutation,
  useUpdateNextQuestionIdToOptionsIdMutation,
  useGetFeaturesQuery,
  useLazyGetFeaturesQuery,
  useAddFeatureMutation,
  useDeleteFeatureMutation,
  useEditFeatureMutation,
  useGetQuestionsSortedByQuestionnaireQuery,
  useGetExercisesQuery,
  useSearchLibraryMutation,
  useGetLibraryCategoriesQuery,
  useLazyGetLibraryCategoriesQuery,
  useAddCategoryMutation,
} = utilsApi;
