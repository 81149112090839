import styles from "./addNewExercise.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { addNewExerciseInitialState } from "../../utils/constants";
import { TAddExerciseRequest } from "../../services/types/utils.types";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddOrEditExerciseForm from "../../components/forms/addOrEditExercise/addOrEditExerciseForm";
import { ActionMeta, SingleValue } from "react-select";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../types/types";

function AddNewExercisePage() {
  const navigate = useNavigate();
  const [form, setForm] = useState<TAddExerciseRequest>(
    addNewExerciseInitialState
  );
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    setForm((prev) => ({ ...prev, [name as string]: value?.value }));
    if (name === "DescQuestionnaire") {
      value && setForm((prev) => ({ ...prev, NameQ: value.value }));
    }
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>הוספת שאלה חדשה</h1>
      <AddOrEditExerciseForm
        form={form}
        handleChange={handleChange}
        onSelectChange={onSelectChange}
        isLoading={false}
        // questionOptions={options}
        onSubmit={(e) => {
          e.preventDefault();
          // addQuestion(form);
          navigate(-1);
        }}
      />
    </section>
  );
}
export default AddNewExercisePage;
