import { Category, TLabeledItem } from "../types/types";

export const menuCategories: Record<Category, string> = {
  [Category.Main]: "דף הבית",
  [Category.Support]: "תמיכה",
  [Category.Library]: "ספריית פריוונצר",
  [Category.Forum]: "פורום",
  [Category.Users]: "משתמשים",
  [Category.Protocols]: "פרוטוקולים",
};

export const protocolCategories: TLabeledItem[] = [
  { value: "questionnaires", label: "שאלונים" },
  { value: "questions", label: "שאלות" },
  { value: "features", label: "פיצ'רים" },
  { value: "library", label: "ספריית פריבנצ'ר" },
  { value: "scores", label: "ציונים" },
  { value: "protocols", label: "פרוטוקולים" },
];

export const userCategories: TLabeledItem[] = [
  { value: "client", label: "מטופלים" },
  { value: "therapist", label: "מטפלים" },
];
