import styles from "./editQuestion.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useReducer } from "react";
import AddOrEditQuestionForm from "../../components/forms/addOrEditQuestion/addOrEditQuestionForm";
import { ActionMeta, SingleValue } from "react-select";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../types/types";
import {
  useAddNextQuestionIdToAllQuestionOptionsMutation,
  useEditQuestionMutation,
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useUpdateNextQuestionIdToOptionsIdMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import {
  questionOptionsSelector,
  questionsSelector,
} from "../../services/selectors/utils.selectors";
import { editQuestionFormInitialState } from "../../utils/constants";
import addOrEditQuestionReducer from "../../utils/addOrEditQuestion.reducer";

function EditQuestionPage() {
  const { id } = useParams();
  useGetQuestionsQuery();
  const questions = useAppSelector(questionsSelector);
  const questionOptions = useAppSelector(questionOptionsSelector);

  const navigate = useNavigate();
  const [form, dispatch] = useReducer(
    addOrEditQuestionReducer,
    editQuestionFormInitialState
  );

  const [editQuestion] = useEditQuestionMutation();
  const [addNextQuestionIdToAllOptions] =
    useAddNextQuestionIdToAllQuestionOptionsMutation();
  const [updateNextQuestionIdToOptionsId] =
    useUpdateNextQuestionIdToOptionsIdMutation();
  const [refreshQuestions] = useLazyGetQuestionsQuery();

  const question = questions.find((q) => q.Id.toString() === id);

  useEffect(() => {
    console.log(question);
    if (question) {
      dispatch({ type: "setForm", payload: question });
    }
  }, [question]);
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: e.target.id, payload: e.target.value });
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    if (name === "NextQuestionDesc") {
      console.log(value);
      id && value && value.value === "OptionId"
        ? updateNextQuestionIdToOptionsId(id)
        : addNextQuestionIdToAllOptions({
            nextQusions: Number(value?.value),
            IdQuestion: Number(id),
          }).then(() => refreshQuestions());
    } else {
      name && dispatch({ type: name, payload: value });
    }
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>לערוך שאלה</h1>
      <AddOrEditQuestionForm
        form={form}
        handleChange={handleChange}
        onSelectChange={onSelectChange}
        isLoading={false}
        questionOptions={questionOptions || []}
        onSubmit={(e) => {
          e.preventDefault();
          editQuestion(form);
          navigate(-1);
        }}
      />
    </section>
  );
}

export default EditQuestionPage;
