import styles from "./questionnaires.module.css";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { searchOptions } from "../../utils/constants";
import {
  useDeleteQuestionnaireMutation,
  useGetQuestionnairesQuery,
  useLazyGetQuestionnairesQuery,
  useSearchQuestionnaireMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { questionnairesSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import { TLabeledItem } from "../../types/types";
import { useDebounce } from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import useMediaQuery from "../../hooks/useMediaQuery";

function QuestionnairesPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const [selectValue, setSelectValue] = useState<TLabeledItem>(
    searchOptions[0]
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [questionnaireToDeleteId, setQuestionnaireToDeleteId] = useState("");
  const [toggle, setToggle] = useState({});

  const { isLoading } = useGetQuestionnairesQuery({ skip: false });

  const questionnaires = useAppSelector(questionnairesSelector);
  const [searchQuestionnaire, { isLoading: isSearchLoading }] =
    useSearchQuestionnaireMutation();
  const [deleteQuestionnaire] = useDeleteQuestionnaireMutation();
  const [refetchQuestionnaires] = useLazyGetQuestionnairesQuery();
  const debouncedValue = useDebounce(inputValue, 500);

  const search = useCallback(async () => {
    searchQuestionnaire({
      query:
        selectValue.value === "Status"
          ? debouncedValue.includes("לא")
            ? "2"
            : "1"
          : debouncedValue,
      clumn: selectValue.value === "Status" ? "StatusId" : selectValue.value,
    });
  }, [debouncedValue, searchQuestionnaire, selectValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const onSelectChange = (value: SingleValue<TLabeledItem>) => {
    setSelectValue(value as TLabeledItem);
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <section className={styles.questionnaires}>
      <div className={styles.header}>
        <button
          type={"button"}
          className={styles.addBtn}
          onClick={() => navigate("add_new")}
        >
          {!mobile && "הוסף שאלון חדש"}
          <span className={styles.plus}>+</span>
        </button>
        <input
          type={"text"}
          placeholder={` סנן לפי ${selectValue.label} `}
          className={styles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
        <Select
          name={"search"}
          options={searchOptions}
          placeholder={"אנא בחר..."}
          className={styles.select}
          defaultValue={selectValue}
          onChange={onSelectChange}
        />
      </div>
      {isLoading || isSearchLoading ? (
        <Loader />
      ) : questionnaires.length > 0 ? (
        mobile ? (
          <ul className={styles.list}>
            {questionnaires.map((q, index) => (
              <li
                key={q.Id}
                className={`listRow_mobile ${
                  toggle[index as keyof typeof setToggle] &&
                  "listRow_mobile_long"
                }`}
                onClick={() => toggleAnswer(index)}
              >
                <p className={styles.listCell}>
                  מספר:<span className={styles.listValue}>{q.Id}</span>
                </p>
                <p className={styles.listCell}>
                  שם:<span className={styles.listValue}>{q.Desc}</span>
                </p>
                <p className={styles.listCell}>
                  סימן:<span className={styles.listValue}>{q.Symbol}</span>
                </p>
                <p className={styles.listCell}>שאלה ראשונית:</p>
                <p className={`${styles.listCell} ${styles.listValue}`}>
                  {q.StartQuestion}
                </p>
                <p className={styles.listCell}>
                  סטטוס:
                  <span className={styles.listValue}>
                    {q.StatusId === 1 ? "פעיל" : "לא פעיל"}
                  </span>
                </p>
                <p className={styles.listCell}>אפשרויות:</p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() =>
                      navigate(`/protocols/questionnaires/edit/${q.Id}`)
                    }
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setQuestionnaireToDeleteId(q.Id.toString());
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className={styles.list}>
            <li className={`${styles.listRow} ${styles.listRow_header}`}>
              <p className={styles.listCell}>מספר:</p>
              <p className={styles.listCell}>שם:</p>
              <p className={styles.listCell}>סימן:</p>
              <p className={styles.listCell}>שאלה ראשונית:</p>
              <p className={styles.listCell}>סטטוס:</p>
              <p className={styles.listCell}>אפשרויות:</p>
            </li>
            {questionnaires.map((q, index) => (
              <li
                key={q.Id}
                className={`${styles.listRow} ${
                  index % 2 === 0 && styles.listRow_colored
                }`}
              >
                <p className={styles.listCell}>{q.Id}</p>
                <p className={styles.listCell}>{q.Desc}</p>
                <p className={styles.listCell}>{q.Symbol}</p>
                <p className={styles.listCell}>{q.StartQuestion}</p>
                <p className={styles.listCell}>
                  {q.StatusId === 1 ? "פעיל" : "לא פעיל"}
                </p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() =>
                      navigate(`/protocols/questionnaires/edit/${q.Id}`)
                    }
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setQuestionnaireToDeleteId(q.Id.toString());
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        )
      ) : (
        <p className={styles.text}>לא נמצאו תוצאות</p>
      )}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onDelete={() => {
              // deleteQuestionnaire(questionnaireToDeleteId);
              refetchQuestionnaires({ skip: false });
            }}
            onClose={() => setOpenModal(false)}
          />
        </Modal>
      )}
    </section>
  );
}

export default QuestionnairesPage;
