import styles from "../../pages/questions/questions.module.css";
import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { IQuestion } from "../../services/types/utils.types";
import { useNavigate } from "react-router-dom";
import Modal from "../modals/modal/Modal";
import DeleteConfirmation from "../modals/deleteConfirmation/deleteConfirmation";
import {
  useDeleteQuestionMutation,
  useLazyGetQuestionnairesQuery,
} from "../../services/api/utils.api";

function QuestionsList({ questionsToShow }: { questionsToShow: IQuestion[] }) {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");
  const [toggle, setToggle] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [questionToDeleteId, setQuestionToDeleteId] = useState("");

  const [deleteQuestion] = useDeleteQuestionMutation();
  const [refetchQuestionnaires] = useLazyGetQuestionnairesQuery();

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };
  if (questionsToShow.length === 0) {
    return <p className={"text"}>אין שאלות</p>;
  }

  const onDeleteButtonClick = (q: IQuestion) => {
    setQuestionToDeleteId(q.Id.toString());
    setOpenModal(true);
  };

  return mobile ? (
    <ul className={styles.list}>
      {questionsToShow.map((q, index) => (
        <li
          key={q.Id}
          className={`listRow_mobile ${
            toggle[index as keyof typeof setToggle] && "listRow_mobile_long"
          }`}
          onClick={() => toggleAnswer(index)}
        >
          <p className={styles.listCell}>
            מס':<span className={styles.listValue}>{q.Id}</span>
          </p>
          <p className={styles.listCell}>
            שאלה:<span className={styles.listValue}>{q.Desc}</span>
          </p>
          <p className={styles.listCell}>
            שאלה הבאה:
            <span className={styles.listValue}>{q.NextQuestionDesc}</span>
          </p>
          <p className={styles.listCell}>
            סטטוס:
            <span className={styles.listValue}>
              {q.StatusId === 1 ? "פעיל" : "לא פעיל"}
            </span>
          </p>
          <p className={styles.listCell}>
            שאלון:
            <span className={styles.listValue}>{q.DescQuestionnaire}</span>
          </p>

          <p className={styles.listCell}>
            סוג התשובה:
            <span className={styles.listValue}>
              {q.DescDataType === "Text" ? "טקסט" : "אופציות"}
            </span>
          </p>
          <p className={styles.listCell}>
            סדר:<span className={styles.listValue}>{q.Seq}</span>
          </p>
          <p className={styles.listCell}>אפשרויות:</p>
          <div className={styles.listCell}>
            <button
              type={"button"}
              className={`${styles.btn} ${styles.btn_type_edit}`}
              onClick={() => navigate(`/protocols/questionnaires/edit/${q.Id}`)}
            />
            <button
              type={"button"}
              className={`${styles.btn} ${styles.btn_type_delete}`}
              onClick={() => onDeleteButtonClick(q)}
            />
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <ul className={styles.list}>
      <li className={`${styles.listRow} ${styles.listRow_header}`}>
        <p className={styles.listCell}>מס':</p>
        <p className={styles.listCell}>שאלון:</p>
        <p className={styles.listCell}>שאלה:</p>
        <p className={styles.listCell}>השאלה הבאה:</p>
        <p className={styles.listCell}>סטטוס:</p>
        <p className={styles.listCell}> סוג התשובה:</p>
        <p className={styles.listCell}>סדר:</p>
        <p className={styles.listCell}>אפשרויות:</p>
      </li>
      {questionsToShow.map((q, index) => (
        <li
          key={q.Id}
          className={`${styles.listRow} ${
            index % 2 === 0 && styles.listRow_colored
          }`}
        >
          <p className={styles.listCell}>{q.Id}</p>
          <p className={styles.listCell}>{q.DescQuestionnaire}</p>
          <p className={styles.listCell}>{q.Desc}</p>
          <p className={styles.listCell}>{q.NextQuestionDesc}</p>
          <p className={styles.listCell}>
            {q.StatusId === 1 ? "פעיל" : "לא פעיל"}
          </p>
          <p className={styles.listCell}>
            {q.DescDataType === "Text" ? "טקסט" : "אופציות"}
          </p>
          <p className={styles.listCell}>{q.Seq}</p>
          <div className={styles.listCell}>
            <button
              type={"button"}
              className={`${styles.btn} ${styles.btn_type_edit}`}
              onClick={() => navigate(`/protocols/questions/edit/${q.Id}`)}
            />
            <button
              type={"button"}
              className={`${styles.btn} ${styles.btn_type_delete}`}
              onClick={() => onDeleteButtonClick(q)}
            />
          </div>
        </li>
      ))}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onDelete={() => {
              deleteQuestion(questionToDeleteId).then((res) => {
                if ("data" in res && res.data) {
                  refetchQuestionnaires({ skip: false });
                }
              });
            }}
            onClose={() => {
              setOpenModal(false);
              setQuestionToDeleteId("");
            }}
          />
        </Modal>
      )}
    </ul>
  );
}
export default QuestionsList;
