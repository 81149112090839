import styles from "./actionReportPage.module.css";
import userInfoStyles from "../../components/userInfo/userInfo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import {
  filterLastOfEachGroup,
  getSelectNote,
  isFormValid,
  TGrouppedQuestionsOptions,
  TLabeledQuestionOption,
  transformServerOptions,
  actionReportId,
} from "../../utils/constants";
import React, { useEffect, useRef, useState } from "react";
import { ActionMeta, MultiValue } from "react-select";
import Modal from "../../components/modals/modal/Modal";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import {
  useGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useInsertAllAnswersQuestionsMutation,
} from "../../services/api/user.api";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import Loader from "../../components/loader/loader";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import {
  TBaseUser,
  TInsertAllAnswersRequest,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
  adminSelector,
  usersSelector,
} from "../../services/selectors/user.selectors";
import UserInfo from "../../components/userInfo/userInfo";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";

function ActionReportPage() {
  const { id, cycle, questionnaire } = useParams();
  const navigate = useNavigate();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const admin = useAppSelector(adminSelector);
  const users = useAppSelector(usersSelector);

  const [insertAllAnswers, { isLoading: isSending }] =
    useInsertAllAnswersQuestionsMutation();

  const [user, setUser] = useState<TBaseUser | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: {
      options: TLabeledQuestionOption[];
      isValid: boolean;
      min: number | null;
      touched: boolean;
      answer?: string;
    };
  }>({});

  useAutosizeTextArea(textAreaRef.current, selectedOptions[109]?.answer || "");

  useEffect(() => {
    const theUser = users.find((u: TBaseUser) => u.id.toString() === id);
    theUser && setUser(theUser);
  }, [users, id]);

  const {
    data,
    isLoading,
    isError: isLoadingError,
  } = useGetReportQuestionsAndOptionsByQuestionnaireIdQuery(actionReportId);

  const sendForm = () => {
    let payload: TInsertAllAnswersRequest[] = [];
    user &&
      Object.keys(selectedOptions).map((k) =>
        payload.push({
          Id: "0",
          QuestionnaireId: actionReportId.toString(),
          QuestionsId: k,
          UsersId: user.id.toString(),
          Answer: selectedOptions[k].answer ?? "",
          AnswerDate: null,
          QuestionsOptionsIds: selectedOptions[k].options
            .map((option) => option.Id.toString())
            .join(", "),
          Cycle: cycle,
        })
      );

    insertAllAnswers({ answers: payload, companyId: admin.companyId }).then(
      (res) => {
        if ("data" in res) {
          setOpenModal(true);
        } else {
          setError(true);
        }
      }
    );
  };
  useEffect(() => {
    if (isLoadingError) {
      setError(true);
    }
  }, [isLoadingError]);
  useEffect(() => {
    if (data) {
      data.forEach((question) =>
        question.Questions.OptionsCountMin
          ? setSelectedOptions((prev) => ({
              ...prev,
              [question.Questions.Id]: {
                options: [],
                isValid: false,
                min: question.Questions.OptionsCountMin,
                touched: false,
              },
            }))
          : false
      );
    }
  }, [data]);
  useEffect(() => {
    if (Object.keys(selectedOptions).length > 0) {
      setFormValid(isFormValid(selectedOptions));
    }
  }, [selectedOptions]);
  const checkedStyles = {
    border: "1px solid  #24a148",
    borderRadius: "4px",
    backgroundColor: "#f7fcf9",
  };

  const formatOptionLabel = (
    option: TGrouppedQuestionsOptions | TLabeledQuestionOption,
    { context }: { context: string }
  ) => {
    if ("GroupId" in option && option.Groups && context === "value") {
      return (
        <div>
          {option.Groups.Name} {option.label}
        </div>
      );
    } else if (context === "menu") {
      return <div>{option.label}</div>;
    } else {
      return <div>{option.label}</div>;
    }
  };

  const onSelectChange = (
    value: MultiValue<TLabeledQuestionOption>,
    { name }: ActionMeta<TLabeledQuestionOption>
  ) => {
    const newValue = filterLastOfEachGroup([...value]);
    const min =
      newValue.length > 0 ? (newValue[0].min ? newValue[0].min : null) : null;
    name &&
      setSelectedOptions((prev) => ({
        ...prev,
        [name]: {
          options: newValue,
          min,
          touched: true,
          isValid:
            prev[name]?.min !== null
              ? (prev[name]?.min ?? 0) <= newValue.length
              : true,
        },
      }));
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h3 className={styles.title}>
        דוח פעולה למשתמש {user?.firstName} {user?.lastName}
      </h3>
      {user && (
        <div className={styles.userInfo}>
          <UserInfo user={user} />
          <div>
            <div>
              <p
                className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
              >
                מחזור:
              </p>
              <p className={userInfoStyles.user__text}>{cycle || "0"}</p>
            </div>
            <div>
              <p
                className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
              >
                שאלון:
              </p>
              <p className={userInfoStyles.user__text}>
                {questionnaire || "ללא שאלון"}
              </p>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <form className={styles.form}>
          {data &&
            data.length > 0 &&
            data
              .slice()
              .sort((a, b) => a.Questions.Seq - b.Questions.Seq)
              .map((question) => (
                <div key={question.Questions.Id} className={styles.formField}>
                  <p
                    className={`${styles.text} ${
                      selectedOptions[question.Questions.Id.toString()]
                        ?.touched &&
                      !selectedOptions[question.Questions.Id.toString()]
                        ?.isValid &&
                      styles.note_red
                    }`}
                  >
                    {question.Questions.OptionsCountMin ? "*" : ""}
                    {question.Questions.Desc}
                  </p>
                  {question.Questions.DataTypesId === 1 ? (
                    <CreatableSelect
                      isMulti={true}
                      name={question.Questions.Id.toString()}
                      placeholder={"לחץ כדי לבחור"}
                      options={transformServerOptions(
                        question.QuestionsOptions,
                        question.Questions.OptionsCountMax,
                        question.Questions.OptionsCountMin
                      )}
                      classNamePrefix="select"
                      formatCreateLabel={(inputText) =>
                        ` הוסף חדש: ${inputText} `
                      }
                      formatOptionLabel={formatOptionLabel}
                      onChange={onSelectChange}
                      value={
                        selectedOptions[question.Questions.Id.toString()]
                          ?.options
                      }
                      isOptionDisabled={() => {
                        const count = question?.Questions?.OptionsCountMax;
                        const isNoGroupForScore =
                          question?.Questions?.IsNoGroupForScore;
                        const groupId = question?.QuestionsOptions[0].GroupId;
                        if (
                          !selectedOptions[question.Questions.Id.toString()]
                        ) {
                          return false;
                        }
                        return (!groupId || isNoGroupForScore) && count
                          ? selectedOptions[question.Questions.Id.toString()]
                              .options.length >= count
                          : false;
                      }}
                      styles={{
                        control: (baseStyles, state) => {
                          const name = state.selectProps.name;
                          const isValid = name
                            ? selectedOptions[name]?.isValid
                            : false;
                          // const isTouched = name
                          //   ? selectedOptions[name]?.touched
                          //   : false;
                          if (isValid) {
                            return { ...baseStyles, ...checkedStyles };
                          } else return baseStyles;
                        },
                      }}
                    />
                  ) : (
                    <textarea
                      ref={textAreaRef}
                      rows={1}
                      className={styles.textarea}
                      name={question.Questions.Id.toString()}
                      id={question.Questions.Id.toString()}
                      onChange={(e) => {
                        setSelectedOptions((prev) => ({
                          ...prev,
                          [e.target.name]: {
                            options: [],
                            answer: e.target.value,
                            min: 0,
                            touched: true,
                            isValid: true,
                          },
                        }));
                      }}
                    />
                  )}
                  <p
                    className={`${styles.note} ${
                      selectedOptions[question.Questions.Id.toString()]
                        ?.touched &&
                      !selectedOptions[question.Questions.Id.toString()]
                        ?.isValid &&
                      styles.note_red
                    }`}
                  >
                    {getSelectNote(
                      question.Questions.OptionsCountMin,
                      question.Questions.OptionsCountMax
                    )}
                  </p>
                </div>
              ))}
          <p className={styles.note}>
            !שימו לב שבאפשרויות מקובצות תוכלו לבחור רק אפשרות אחת מתוך קבוצה
          </p>
          <button
            type={"button"}
            className={`button ${styles.submitBtn} ${
              (!formValid || isSending) && styles.submitBtn_disabled
            }`}
            onClick={sendForm}
            disabled={!formValid || isSending}
          >
            {isSending ? "שליחה" : 'שלח דו"ח'}
          </button>
        </form>
      )}
      {openModal && (
        <Modal
          onClose={() => {
            setOpenModal(false);
            navigate(-1);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModal(false);
              navigate(-1);
            }}
          />
        </Modal>
      )}
      {error && (
        <Modal onClose={() => setError(false)}>
          <ErrorPopup onClose={() => setError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default ActionReportPage;
