import styles from "./addNewQuestionnaire.module.css";
import { ActionMeta, SingleValue } from "react-select";
import { addNewQuestionnaireInitialState } from "../../utils/constants";
import { ChangeEvent, FormEvent, useReducer, useState } from "react";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../types/types";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";
import { DateObject } from "react-multi-date-picker";
import { useCreateQuestionnaireMutation } from "../../services/api/utils.api";
import AddOrEditQuestionnaireForm from "../../components/forms/addOrEditQuestionnaire/addOrEditQuestionnaireForm";
import addOrEditQuestionnaireReducer from "../../utils/addOrEditQuestionnaire.reducer";
import Modal from "../../components/modals/modal/Modal";
import CreateNewQuestionnaireSuccessConfirmation from "../../components/modals/createNewQuestionnaireSuccessConfirmation/createNewQuestionnaireSuccessConfirmation";

function AddNewQuestionnairePage() {
  const navigate = useNavigate();

  const [form, dispatch] = useReducer(
    addOrEditQuestionnaireReducer,
    addNewQuestionnaireInitialState
  );
  const [openModal, setOpenModal] = useState(false);

  const [createQuestionnaire, { isLoading }] = useCreateQuestionnaireMutation();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: e.target.id, payload: e.target.value });
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    name && dispatch({ type: name, payload: value });
  };
  const onDateChange = (nv: DateObject) => {
    if (form.periodicity?.value === "Monthly") {
      dispatch({ type: "monthi", payload: nv.month.number.toString() });
    } else dispatch({ type: "Date", payload: nv ? nv.toString() : null });
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { periodicity, ...rest } = form;
    createQuestionnaire(rest).then((response) => {
      console.log(response);
      if ("data" in response && response.data) {
        setOpenModal(true);
      }
    });
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>הוספת שאלון חדש</h1>
      <AddOrEditQuestionnaireForm
        form={form}
        onSubmit={onSubmit}
        onSelectChange={onSelectChange}
        handleChange={handleChange}
        isLoading={isLoading}
        onDateChange={onDateChange}
      />
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          isLoading && styles.form__btn_disabled
        } `}
        disabled={isLoading}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : "ליצור שאלון"}
      </button>
      {openModal && (
        <Modal onClose={() => navigate("/protocols/questionnaires")}>
          <CreateNewQuestionnaireSuccessConfirmation />
        </Modal>
      )}
    </section>
  );
}

export default AddNewQuestionnairePage;
