import styles from "../addNewQuestionnaire/addNewQuestionnaire.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import AddOrEditQuestionnaireForm from "../../components/forms/addOrEditQuestionnaire/addOrEditQuestionnaireForm";
import { useNavigate, useParams } from "react-router-dom";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAppSelector } from "../../services/hooks";
import {
  questionnairesSelector,
  questionsSelector,
} from "../../services/selectors/utils.selectors";
import {
  useEditQuestionnaireMutation,
  useGetQuestionsQuery,
  useLazyGetQuestionnairesQuery,
} from "../../services/api/utils.api";
import { ActionMeta, SingleValue } from "react-select";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../types/types";
import { DateObject } from "react-multi-date-picker";
import addOrEditQuestionnaireReducer from "../../utils/addOrEditQuestionnaire.reducer";
import { addNewQuestionnaireInitialState } from "../../utils/constants";
import QuestionsList from "../../components/questionsList/questionsList";
import { IQuestion } from "../../services/types/utils.types";

function EditQuestionnairePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  useGetQuestionsQuery();

  const questions = useAppSelector(questionsSelector);
  const questionnaires = useAppSelector(questionnairesSelector);
  const questionnaire = questionnaires.find((q) => q.Id.toString() === id);
  // console.log(id, questionnaires, questions, questionnaire);
  const [form, dispatch] = useReducer(
    addOrEditQuestionnaireReducer,
    addNewQuestionnaireInitialState
  );
  const [questionsToShow, setQuestionsoShow] = useState<IQuestion[]>([]);

  const [editQuestionnaire, { isLoading }] = useEditQuestionnaireMutation();
  const [refetchQuestionnaires] = useLazyGetQuestionnairesQuery();

  useEffect(() => {
    // console.log("questions");
    setQuestionsoShow(
      questions.filter((q) => q.DescQuestionnaire === questionnaire?.Desc)
    );
  }, [questions]);
  useEffect(() => {
    if (!questionnaire) {
      navigate(-1);
    } else {
      const {
        StatusId,
        Dayly,
        Monthly,
        QuestionnaireTypesId,
        DefaultId,
        ...rest
      } = questionnaire;
      if (Dayly) {
        dispatch({
          type: "periodicity",
          payload: { value: "Dayly", label: "שאלון יומי" },
        });
        dispatch({ type: "Date", payload: Dayly });
      }
      if (Monthly) {
        dispatch({
          type: "periodicity",
          payload: { value: "Monthly", label: "שאלון חודשי" },
        });
        dispatch({ type: "monthi", payload: Monthly.toString() });
      }
      if (QuestionnaireTypesId) {
        dispatch({ type: "type", payload: QuestionnaireTypesId.toString() });
      }
      if (DefaultId) {
        console.log(DefaultId);
        dispatch({
          type: "periodicity",
          payload: { value: "Default", label: "" },
        });
        dispatch({ type: "default", payload: DefaultId.toString() });
      }
      if (StatusId !== undefined) {
        dispatch({
          type: "StatusId",
          payload: Number(StatusId) == 0 ? "false" : "true",
        });
      }
      console.log(Object.keys(rest));
      // @ts-ignore
      Object.keys(rest).forEach((r) => dispatch({ type: r, payload: rest[r] }));
    }
  }, [questionnaire, navigate]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { periodicity, type, StatusId, monthi, Date, ...rest } = form;
    editQuestionnaire({
      ...rest,
      Id: Number(id),
      QuestionnaireTypesId: type,
      StatusId: StatusId === "true",
      Monthly: monthi,
      Dayly: Date,
      DefaultId: form.default,
    }).then((response) => {
      if ("data" in response && !!response.data) {
        console.log(response.data);
        refetchQuestionnaires({ skip: false });
      }
    });
    navigate("/protocols/questionnaires");
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    name && dispatch({ type: name, payload: value });
  };
  const onDateChange = (nv: DateObject) => {
    console.log(nv);
    if (form.periodicity?.value === "Monthly") {
      dispatch({ type: "monthi", payload: nv.month.number.toString() });
    } else dispatch({ type: "Date", payload: nv ? nv.toString() : null });
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: e.target.id, payload: e.target.value });
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>לערוך שאלון</h1>
      <AddOrEditQuestionnaireForm
        form={form}
        onSubmit={onSubmit}
        onSelectChange={onSelectChange}
        handleChange={handleChange}
        isLoading={isLoading}
        onDateChange={onDateChange}
      />
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          isLoading && styles.form__btn_disabled
        } `}
        disabled={isLoading}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : id ? "לערוך שאלון" : "ליצור שאלון"}
      </button>
      <h2 className={styles.section__title}>כל השאלות</h2>
      {questionsToShow.length ? (
        <QuestionsList questionsToShow={questionsToShow} />
      ) : (
        <p className={"text"}>לא נמצאו תוצאות</p>
      )}
    </section>
  );
}
export default EditQuestionnairePage;
