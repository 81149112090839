import styles from "./addNewFeature.module.css";
import { addNewFeatureInitialState } from "../../utils/constants";
import { ChangeEvent, FormEvent, useState } from "react";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";
import { useAddFeatureMutation } from "../../services/api/utils.api";
import AddOrEditFeatureForm from "../../components/forms/addOrEditFeature/addOrEditFeature";

function AddNewFeaturePage() {
  const navigate = useNavigate();

  const [form, setForm] = useState(addNewFeatureInitialState);

  const [addNewFeature, { isLoading }] = useAddFeatureMutation();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    addNewFeature(form).then((response) => {
      console.log(response);
      navigate("/protocols/features");
    });
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>הוספת הפיצ'ר חדש</h1>
      <AddOrEditFeatureForm
        form={form}
        onSubmit={onSubmit}
        handleChange={handleChange}
        isLoading={isLoading}
      />
    </section>
  );
}

export default AddNewFeaturePage;
