import styles from "./userInfo.module.css";
import React from "react";
import { calculateAge, noDataLabel } from "../../utils/constants";
import { TBaseUser } from "../../services/types/user.types";

function UserInfo({
  user,
  customClassName,
}: {
  user: TBaseUser;
  customClassName?: string;
}) {
  return (
    <section className={`${styles.userSection} ${customClassName}`}>
      <article className={styles.user}>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            שם משתמש:
          </p>
          <p className={styles.user__text}>{user.fullName}</p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            מס' משתמש:
          </p>
          <p className={styles.user__text}>{user.id}</p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            גיל:
          </p>
          <p className={styles.user__text}>
            {user.birth ? calculateAge(user.birth) : "אין מידע"}
          </p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            נייד:
          </p>
          <p className={styles.user__text}>{user?.phone || noDataLabel}</p>
        </div>
      </article>
    </section>
  );
}

export default UserInfo;
