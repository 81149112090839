import * as Yup from "yup";

export const israeliMobileRegExp = /^(05\d|9725\d)([-]?)\d{7}$/;

export const loginEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .email("אימייל לא תקין"),
  password: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(6, "הסיסמה צריכה להכיל לפחות 6 תווים"),
});

export const loginSMSValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("נא להזין מספר נייד")
    .matches(israeliMobileRegExp, "מספר נייד לא תקין"),
});

export const addNewUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם הפרטי צריך לכלול לפחות שני תווים"),
  lastName: Yup.string().min(2, "השם משפחה צריך לכלול לפחות שני תווים"),
  phone: Yup.string().matches(israeliMobileRegExp, "מספר נייד לא תקין"),
  email: Yup.string().email("אימייל לא תקין"),
  birth: Yup.date()
    .min(new Date(1920, 0, 1), "תאריך לידה לא תקין")
    .max(new Date(), "תאריך לידה לא תקין"),
  passport: Yup.string()
    .min(9, "תעודת זהות צריכה להכיל 9 תווים")
    .max(9, "תעודת זהות צריכה להכיל 9 תווים"),
});
