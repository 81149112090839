import styles from "./questionReportsPage.module.css";
import userInfoStyles from "../../components/userInfo/userInfo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { IOption } from "../../types/types";
import {
  findLatestQuestionnaire,
  RRMListDefault,
  noDataLabel,
  selectCustomStyles,
} from "../../utils/constants";
import React, { useEffect, useRef, useState } from "react";
import QuestionReport from "../../components/questionReport/questionReport";
import { useAppSelector } from "../../services/hooks";
import { usersSelector } from "../../services/selectors/user.selectors";
import UserInfo from "../../components/userInfo/userInfo";
import {
  useAddMessageToScoreMutation,
  useGetAllQuestionnairesByUserIdQuery,
  useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery,
} from "../../services/api/user.api";
import {
  TRRMListEntry,
  TQuestionnaire,
  TQuestionnaireEntry,
  TQuestionnaireQuestion,
  TMessage,
} from "../../services/types/user.types";
import RrmLink from "../../components/rrmLink/rrmLink";
import { getPrintDate, getTimeFromTimestamp } from "../../utils/utils";
import Select, { SingleValue } from "react-select";
import Modal from "../../components/modals/modal/Modal";
import AddRecommendationPopup from "../../components/modals/addRecommendation/addRecommendation";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";

function QuestionReportsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = id as string;
  const users = useAppSelector(usersSelector);
  const user = users.find((u) => u.id.toString() === userId);

  const [cycle, setCycle] = useState<number | null>(null);
  const [allReports, setAllReports] = useState<TQuestionnaireEntry[]>([]);
  const [latestActionReport, setLatestActionReport] = useState<
    TQuestionnaireEntry | undefined
  >(undefined);
  const [latestUserReport, setLatestUserReport] = useState<
    TQuestionnaireEntry | undefined
  >(undefined);
  const [score, setScore] = useState<number>(0);
  const [sources, setSources] = useState<number[] | undefined>(undefined);
  const [reportToShow, setReportToShow] = useState<
    | {
        questionnaire: TQuestionnaire;
        results: TQuestionnaireQuestion[];
        score: number;
        rrm: number;
        rrmList: TRRMListEntry[];
      }
    | undefined
  >(undefined);
  const [actionReport, setActionReport] = useState<
    | {
        questionnaire: TQuestionnaire;
        results: TQuestionnaireQuestion[];
        score: number;
        rrm: number;
        rrmList: TRRMListEntry[];
      }
    | undefined
  >(undefined);
  const [reportsDateOptions, setReportsDateOptions] = useState<IOption[]>([]);
  const [actionReportsDateOptions, setActionReportsDateOptions] = useState<
    IOption[]
  >([]);
  const [RRM, setRRM] = useState<number | undefined>(undefined);

  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [addMessageToTheScore] = useAddMessageToScoreMutation();
  const [messagesByScore, setMessagesByScore] = useState<TMessage[]>([]);

  const closeAllModals = () => {
    setOpenModal(false);
    setOpenModalSuccess(false);
    setOpenModalError(false);
  };
  const [getMultipleReportsByUserIdAndCycle] =
    useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery();
  console.log(cycle, latestActionReport, latestUserReport);
  useEffect(() => {
    typeof cycle === "number" &&
      getMultipleReportsByUserIdAndCycle({
        UsersId: userId,
        Cycle: cycle,
        QuestionnairesIds: "26,27",
      }).then((res) => {
        if ("data" in res && res.data?.QuestionnairesAndResults) {
          const scoreDataUR = res.data.QuestionnairesScoreList.find(
            (item) => item.QuestionnaireId === 26
          );
          const scoreDataTR = res.data.QuestionnairesScoreList.find(
            (item) => item.QuestionnaireId === 27
          );
          scoreDataUR &&
            setReportToShow({
              questionnaire:
                res.data.QuestionnairesAndResults.Questionnaire_26[0],
              results: res.data.QuestionnairesAndResults.QuestionsResults_26,
              score: scoreDataUR.Score || 0,
              rrm: scoreDataUR.RRM || 0,
              rrmList: scoreDataUR.RRMList,
            });
          scoreDataTR &&
            setActionReport({
              questionnaire:
                res.data.QuestionnairesAndResults.Questionnaire_27[0],
              results: res.data.QuestionnairesAndResults.QuestionsResults_27,
              score: scoreDataTR.Score || 0,
              rrm: scoreDataTR.RRM || 0,
              rrmList: scoreDataTR.RRMList,
            });
          setRRM(res.data.TotalScoreRRM.RRM);
          setScore(res.data.TotalScoreRRM.TotalScore);
          setMessagesByScore(res.data.MessageByScoreList || []);
          setSources(
            res.data.QuestionnairesScoreList.filter((i) => i.Score > 0).map(
              (item) => item.QuestionnaireId
            )
          );
        }
      });
  }, [cycle, getMultipleReportsByUserIdAndCycle, userId]);
  const timestampRef = useRef(Date.now()).current;
  const { data } =
    // @ts-ignore
    useGetAllQuestionnairesByUserIdQuery({ id, time: timestampRef });

  useEffect(() => {
    if (data) {
      setAllReports(data.Questionnaires);
      const latestUserReport = findLatestQuestionnaire(26, data.Questionnaires);
      const latestActionReport = findLatestQuestionnaire(
        27,
        data.Questionnaires
      );
      latestUserReport && setLatestUserReport(latestUserReport);
      latestActionReport && setLatestActionReport(latestActionReport);
      setReportsDateOptions(
        data.Questionnaires.filter((qu) => qu.QuestionnaireId === 26).map(
          (q) => ({
            label: `${getPrintDate(
              new Date(q.TimeStamp),
              true
            )} (${getTimeFromTimestamp(new Date(q.TimeStamp))}) (${q.Cycle})`,
            value: q.TimeStamp,
          })
        )
      );
      setActionReportsDateOptions(
        data.Questionnaires.filter((qu) => qu.QuestionnaireId === 27).map(
          (q) => ({
            label: `${getPrintDate(
              new Date(q.TimeStamp),
              true
            )} (${getTimeFromTimestamp(new Date(q.TimeStamp))}) (${q.Cycle})`,
            value: q.TimeStamp,
          })
        )
      );
    }
  }, [data]);
  const onSelectChange = (newValue: SingleValue<IOption>) => {
    const newCycle = allReports.find(
      (r) => r.TimeStamp === newValue?.value
    )?.Cycle;
    if (newCycle) {
      setCycle(newCycle);
    }
  };

  return (
    <article className={styles.section}>
      {RRM && (
        <RrmLink RRMScore={RRM} score={score} scoreList={RRMListDefault} />
      )}
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h2 className={`title`}>
        {reportToShow
          ? ` דוחות שאלונים | ${reportToShow.questionnaire.Desc}`
          : "דוחות שאלונים"}
      </h2>
      {reportToShow ? (
        <article className={styles.table}>
          {user && (
            <div className={styles.table__header}>
              <UserInfo user={user} />
              <div className={styles.table__columns}>
                <div>
                  <p
                    className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
                  >
                    פרופיל ציון משוקלל:
                  </p>
                  <p className={userInfoStyles.user__text}>{score}</p>
                </div>
                <div className={styles.centeredFlex}>
                  <button
                    className={`${styles.btn} hover`}
                    onClick={() => setOpenModal(true)}
                  >
                    להוסיף המלצה
                  </button>
                  {messagesByScore.length > 0 && (
                    <p
                      className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
                    >
                      &#10003; המלצה נשלחה
                    </p>
                  )}
                </div>
                <div>
                  <p
                    className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
                  >
                    מקור:
                  </p>
                  <ul className={styles.sources}>
                    {sources &&
                      sources.map((s, index) => (
                        <li key={s} className={userInfoStyles.user__text}>
                          {s === 26 ? "שאלון POC" : "דוח פעולה"}
                          {index < sources.length - 1 ? "," : ""}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className={styles.centeredFlex}>
                  <button
                    className={`${styles.btn} ${styles.btn_white} hover`}
                    onClick={() => {
                      navigate(
                        `/users/${userId}/report/${cycle || "0"}/${
                          reportToShow.questionnaire.Desc
                        }`
                      );
                    }}
                  >
                    דוח פעולה
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className={styles.content}>
            <h2 className={styles.header}>
              <div className={styles.headerContainer}>
                <Select
                  name={"reportDate"}
                  className={styles.select}
                  options={reportsDateOptions}
                  value={
                    reportsDateOptions.find(
                      (r) =>
                        r.value ===
                        allReports.find(
                          (r) => r.QuestionnaireId === 26 && r.Cycle === cycle
                        )?.TimeStamp
                    ) || null
                  }
                  onChange={onSelectChange}
                  styles={selectCustomStyles}
                  isDisabled={reportsDateOptions.length < 1}
                  placeholder={noDataLabel}
                />
                <p className={styles.headerTitle}>
                  {reportToShow.questionnaire.Desc}
                </p>
                <div className={styles.headerRrm}>
                  <RrmLink
                    RRMScore={reportToShow.rrm}
                    score={reportToShow.score}
                    scoreList={reportToShow.rrmList}
                    additionalStyles={{
                      position: "static",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <p className={styles.headerText}>
                  גורם מדווח : {noDataLabel}
                  {/*{reportToShow.manager*/}
                  {/*  ? `גורם מדווח : ${reportToShow.manager}`*/}
                  {/*  : ""}*/}
                </p>
                <p className={styles.headerText}>
                  ציון שאלון: {reportToShow.score}
                </p>
              </div>
              <div className={styles.headerContainer}>
                <Select
                  name={"actionReportDate"}
                  className={styles.select}
                  options={actionReportsDateOptions}
                  onChange={onSelectChange}
                  placeholder={noDataLabel}
                  value={
                    actionReportsDateOptions.find(
                      (r) =>
                        r.value ===
                        allReports.find(
                          (r) => r.QuestionnaireId === 27 && r.Cycle === cycle
                        )?.TimeStamp
                    ) || null
                  }
                  styles={selectCustomStyles}
                  isDisabled={actionReportsDateOptions.length < 1}
                />
                <p className={styles.headerTitle}>
                  {actionReport?.questionnaire.Desc}
                </p>
                <div className={styles.headerRrm}>
                  {actionReport && actionReport.score > 0 && (
                    <RrmLink
                      RRMScore={actionReport.rrm}
                      score={actionReport.score}
                      scoreList={reportToShow.rrmList}
                      additionalStyles={{
                        position: "static",
                        backgroundColor: "transparent",
                      }}
                    />
                  )}
                </div>

                <p className={styles.headerText}>
                  גורם מדווח : {noDataLabel}
                  {/*{actionReport?.manager*/}
                  {/*  ? `גורם מדווח : ${actionReport?.manager}`*/}
                  {/*  : ""}*/}
                </p>
                <p className={styles.headerText}>
                  {actionReport
                    ? `ציון שאלון:
                  ${actionReport.score}`
                    : ""}
                </p>
              </div>
            </h2>
            {reportToShow && <QuestionReport report={reportToShow} />}
            {actionReport && actionReport.score > 0 && (
              <QuestionReport report={actionReport} />
            )}
            <textarea
              className={`input ${styles.textarea}`}
              placeholder={"המלצות לגורם מטפל"}
            />
          </div>
        </article>
      ) : (
        <ul className={styles.editOptions}>
          {latestUserReport && (
            <li
              className={`${styles.editOption} hover`}
              onClick={() => {
                setCycle(latestUserReport.Cycle);
              }}
            >
              {latestUserReport.Desc} ( מחזור {latestUserReport.Cycle})
              <span className={styles.editOptionComment}>
                {getPrintDate(new Date(latestUserReport.TimeStamp))} (
                {getTimeFromTimestamp(new Date(latestUserReport.TimeStamp))})
              </span>
            </li>
          )}

          {latestActionReport && (
            <li
              className={`${styles.editOption} hover`}
              onClick={() => {
                setCycle(latestActionReport.Cycle);
              }}
            >
              {latestActionReport.Desc} ( מחזור {latestActionReport.Cycle})
              <span className={styles.editOptionComment}>
                {getPrintDate(new Date(latestActionReport.TimeStamp))} (
                {getTimeFromTimestamp(new Date(latestActionReport.TimeStamp))})
              </span>
            </li>
          )}
          {!latestUserReport && !latestActionReport && (
            <p className={styles.headerText}>לא נמצאו דוחות עבור משתמש זה</p>
          )}
        </ul>
      )}
      {openModal && user && (
        <Modal onClose={() => setOpenModal(false)}>
          <AddRecommendationPopup
            user={user}
            addMessageToScore={(form: { Title: string; Desc: string }) => {
              sources &&
                addMessageToTheScore({
                  QuestionnaireIds: sources.join(","),
                  MessageId: "0",
                  Title: form.Title,
                  Desc: form.Desc,
                  Score: score.toString(),
                  UsersId: userId,
                  Cycle: cycle,
                })
                  .then((res) => {
                    if ("data" in res) {
                      setOpenModal(false);
                      setOpenModalSuccess(true);
                    } else {
                      setOpenModal(false);
                      setOpenModalError(true);
                    }
                  })
                  .finally(
                    () =>
                      cycle &&
                      getMultipleReportsByUserIdAndCycle({
                        UsersId: userId,
                        Cycle: cycle,
                        QuestionnairesIds: "26,27",
                      }).then((res) => {
                        const newMessageList =
                          res.data?.MessageByScoreList || [];
                        setMessagesByScore(newMessageList);
                      })
                  );
            }}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={closeAllModals}>
          <GeneralSuccessPopup onClose={closeAllModals} />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={closeAllModals}>
          <ErrorPopup onClose={closeAllModals} />
        </Modal>
      )}
    </article>
  );
}

export default QuestionReportsPage;
