import styles from "./usersList.module.css";
import React, { useState } from "react";
import { TBaseUser } from "../../services/types/user.types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Modal from "../modals/modal/Modal";
import PrivacyProtectionPopup from "../modals/privacyProtectionPopup/privacyProtectionPopup";

function UsersList({ userList }: { userList: TBaseUser[] }) {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const [id, setId] = useState<number | undefined>(undefined);
  const [openPrivacyPopup, setOpenPrivacyPopup] = useState(false);

  const proceed = () => navigate(`${id}/actions`);

  return (
    <>
      <div className={styles.usersListHeader}>
        <p className={styles.usersListTitle}>פעולות:</p>
        <p className={styles.usersListTitle}>מס' משתמש:</p>
        <p className={styles.usersListTitle}>שם משתמש:</p>
        <p className={styles.usersListTitle}>אימייל:</p>
        <p className={styles.usersListTitle}>טלפון:</p>
        <p className={styles.usersListTitle}>המלצה:</p>
      </div>

      <ul className={`${styles.usersList} scroll`}>
        {userList.map((u: TBaseUser, index: number) =>
          !mobile ? (
            <li
              key={index}
              className={`${styles.user} ${
                index % 2 === 0 && styles.user_colored
              }`}
            >
              <p className={styles.usersListCell}>
                <button
                  className={`${styles.btn} ${styles.btn_type_actions}`}
                  onClick={() => {
                    setId(u.id);
                    setOpenPrivacyPopup(true);
                  }}
                >
                  פעולות
                </button>
              </p>
              <p className={styles.usersListCell}>{u.id}</p>
              <p className={styles.usersListCell}>
                {u.firstName} {u.lastName || ""}
              </p>
              <p className={styles.usersListCell}>{u.email || "-"}</p>
              <p className={styles.usersListCell}>{u.phone || "-"}</p>
              <p className={`${styles.usersListCell} ${styles.recommendation}`}>
                {u?.recommends ? `${u.recommends.slice(0, 7)} ...` : "-"}
                {u?.recommends && (
                  <span className={styles.recommendation_full}>
                    {u.recommends}
                  </span>
                )}
              </p>
            </li>
          ) : (
            <li key={index} className={styles.user_mobile}>
              <p className={styles.userName}>
                <span className={styles.userNameLabel}> שם משתמש:</span>
                {u.firstName} {u.lastName || ""}
              </p>
              <p className={styles.usersListCell}>
                <button
                  className={`${styles.btn} ${styles.btn_type_actions}`}
                  onClick={() => {
                    setId(u.id);
                    setOpenPrivacyPopup(true);
                  }}
                >
                  פעולות
                </button>
              </p>
            </li>
          )
        )}
      </ul>
      {openPrivacyPopup && (
        <Modal
          onClose={() => {
            setOpenPrivacyPopup(false);
            setId(undefined);
          }}
        >
          <PrivacyProtectionPopup onClose={proceed} />
        </Modal>
      )}
    </>
  );
}

export default UsersList;
