import React, { ReactNode, useState } from "react";
import { Category } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import styles from "./mainContentLayout.module.css";
import Sidebar from "../sidebar/sidebar";
import Notifications from "../notifications/notifications";
import { mobileWidth } from "../../utils/constants";
import ModalOverlay from "../modals/modalOverlay/ModalOverlay";
import {menuCategories} from "../../utils/categories";

interface IMainContentLayoutProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
  children: ReactNode;
}

function MainContentLayout({
  activeCategory,
  changeCategory,
  children,
}: IMainContentLayoutProps) {
  const mobile = useMediaQuery(mobileWidth);
  const initialState = !mobile;
  const [openMenu, setOpenMenu] = useState(initialState);
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  return (
    <main className={`${styles.main}`}>
      {mobile && openMenu && (
        <ModalOverlay onClick={() => setOpenMenu(false)} />
      )}
      <section className={`${styles.mainContent}`} onClick={hideNotifications}>
        <Notifications showNotifications={showNotifications} />
        <div className={`${styles.header}`}>
          <h1 className={`${styles.mainContent__title}`}>
            {menuCategories[activeCategory]}
          </h1>
          <button
            className={`${styles.mainContent__bell} ${
              (showNotifications || !mobile) &&
              styles.mainContent__bell_invisible
            }`}
            onClick={() => {
              setShowNotifications(!showNotifications);
            }}
          />
          <button
            type="button"
            onClick={toggleMenu}
            className={styles.burger}
          />
        </div>
        {children}
      </section>
      <Sidebar
        openMenu={openMenu}
        toggleMenu={toggleMenu}
        changeCategory={changeCategory}
        activeCategory={activeCategory}
      />
    </main>
  );
}
export default MainContentLayout;
