import popupStyles from "./createNewQuestionnaireSuccessConfirmation.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";
import { useNavigate } from "react-router-dom";

function CreateNewQuestionnaireSuccessConfirmation() {
  const navigate = useNavigate();
  return (
    <article className={popupStyles.popup}>
      <img className={popupStyles.popup__img} src={venchyImg} alt={"וונצ’י"} />
      <p className={popupStyles.popup__text}>שאלון חדש נוצר בהצלחה</p>
      <button
        className={`button button_white ${popupStyles.popup__nextBtn} hover`}
        onClick={() => {
          navigate("/protocols/questionnaires");
        }}
      >
        לראות את כל השאלונים
      </button>
      <button
        className={`button ${popupStyles.popup__nextBtn} hover`}
        onClick={() => {
          navigate("/protocols/questions/add_new");
        }}
      >
        להוסיף שאלות לשאלון החדש
      </button>
    </article>
  );
}

export default CreateNewQuestionnaireSuccessConfirmation;
