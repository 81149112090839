import styles from "./protocols.module.css";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { protocolCategories } from "../../utils/categories";

function ProtocolsPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery(mobileWidth);

  return (
    <section className={styles.protocols}>
      {!mobile && (
        <BackArrowButton
          text={"למסך הקודם"}
          onClick={() => navigate(-1)}
          position={"center"}
        />
      )}
      <ul className={`options`}>
        {protocolCategories.map((cat) => (
          <Link
            to={`/protocols/${cat.value}`}
            key={cat.value}
            className={`option hover`}
          >
            {cat.label}
          </Link>
        ))}
        {mobile && (
          <button
            onClick={() => navigate(-1)}
            className={`button ${styles.btn} hover`}
          >
            למסך הקודם
          </button>
        )}
      </ul>
    </section>
  );
}

export default ProtocolsPage;
