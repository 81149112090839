import popupStyles from "./deleteConfirmation.module.css";
import { IPopupWithoutProps } from "../../../types/types";
import venchyImg from "../../../images/questionnaire_doctor.png";

function DeleteConfirmation({
  onClose,
  onDelete,
}: IPopupWithoutProps & { onDelete: () => void }) {
  return (
    <article className={popupStyles.popup}>
      <img className={popupStyles.popup__img} src={venchyImg} alt={"וונצ’י"} />
      <p className={popupStyles.popup__text}>אם תאשר זה ימחק לצמיתות</p>
      <button
        className={`button ${popupStyles.popup__nextBtn} hover`}
        onClick={() => {
          onDelete();
          onClose();
        }}
      >
        מחק
      </button>
    </article>
  );
}

export default DeleteConfirmation;
