import { RootState } from "../types";

export const questionnairesSelector = (state: RootState) =>
  state.utils.questionnaires;
export const questionsSelector = (state: RootState) => state.utils.questions;
export const questionsSortedByQuestionnaireSelector = (state: RootState) =>
  state.utils.questionsSortedByQuestionnaire;
export const dataTypeSelector = (state: RootState) => state.utils.dataType;
export const questionOptionsSelector = (state: RootState) =>
  state.utils.questionOptions;
export const featuresSelector = (state: RootState) => state.utils.features;
export const exercisesSelector = (state: RootState) => state.utils.exercises;
export const categoriesSelector = (state: RootState) =>
  state.utils.libraryCategories;
