import styles from "./usersPage.module.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import { filterObjectsByString } from "../../utils/search";
import { useAppSelector } from "../../services/hooks";
import {
  usersSelector,
  adminSelector,
  therapistsSelector,
  hosenUsersSelector,
  hosenUsersWithRecommendationsSelector,
} from "../../services/selectors/user.selectors";
import { TUserForm } from "../../types/types";
import {
  useLazyGetAllUsersWithRecommendationsQuery,
  useTakeUserMutation,
} from "../../services/api/user.api";
import AddButton from "../../components/addButton/addButton";
import AddNewUserForm from "../../components/forms/addNewUserForm/addNewUserForm";
import { TTakeUserRequest } from "../../services/types/user.types";
import Modal from "../../components/modals/modal/Modal";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import CloseButton from "../../components/closeButton/closeButton";
import UsersList from "../../components/usersList/usersList";
import { userCategories } from "../../utils/categories";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function UsersPage() {
  const admin = useAppSelector(adminSelector);
  const users = useAppSelector(usersSelector);
  const therapists = useAppSelector(therapistsSelector);
  const hosenUsers = useAppSelector(hosenUsersSelector);
  const hosenUsersWithRecommendations = useAppSelector(
    hosenUsersWithRecommendationsSelector
  );
  const [refetchUsers] = useLazyGetAllUsersWithRecommendationsQuery();
  const [takeUser] = useTakeUserMutation();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [usersCategory, setUsersCategory] = useState<string | undefined>(
    undefined
  );
  const [userList, setUserList] = useState(users);
  const [inputValue, setInputValue] = useState<string>("");
  const [checkedShowHosenUsers, setCheckedShowHosenUsers] = useState(false);
  const [
    checkedShowHosenUsersWithRecommendations,
    setCheckedShowHosenUsersWithRecommendations,
  ] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setInputValue("");
    if (usersCategory === "therapist") {
      setUserList(therapists);
    } else {
      const userList = checkedShowHosenUsersWithRecommendations
        ? hosenUsersWithRecommendations
        : checkedShowHosenUsers
        ? hosenUsers
        : users;

      setUserList(userList);
    }
  }, [
    users,
    therapists,
    usersCategory,
    checkedShowHosenUsers,
    checkedShowHosenUsersWithRecommendations,
    hosenUsers,
    hosenUsersWithRecommendations,
  ]);
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    const userList =
      usersCategory === "therapist"
        ? therapists
        : checkedShowHosenUsersWithRecommendations
        ? hosenUsersWithRecommendations
        : checkedShowHosenUsers
        ? hosenUsers
        : users;

    if (userList) {
      setUserList(() => filterObjectsByString(event.target.value, userList));
    }
  };
  const sendTakeUserFormToServer = (values: TUserForm) => {
    let payload: TTakeUserRequest = {
      Id: "0",
      TherapistsId: admin.id,
      FirstName: "",
      GenderId: 3,
      CompanyId: admin.companyId,
      ...(usersCategory === "therapist" && { RoleId: 2 }),
    };
    for (let key in values) {
      payload[key.charAt(0).toUpperCase() + key.slice(1)] = values[key];
    }
    takeUser(payload)
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenErrorModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        refetchUsers(admin.companyId);
        setShowForm(false);
      });
  };

  return (
    <section className={styles.users}>
      {usersCategory ? (
        <article className={styles.usersTable}>
          <BackArrowButton
            text={"למסך הקוד"}
            onClick={() => setUsersCategory(undefined)}
            position={"center"}
          />
          <div className={styles.addButtonContainer}>
            {showForm ? (
              <CloseButton onClick={() => setShowForm(false)} />
            ) : (
              <AddButton
                onClick={() => {
                  setShowForm(true);
                }}
                title={
                  usersCategory === "therapist"
                    ? "הוסף מטפל חדש"
                    : "הוסף משתמש חדש"
                }
              />
            )}
          </div>
          {showForm && <AddNewUserForm onSubmit={sendTakeUserFormToServer} />}
          <div className={styles.usersContent}>
            <div className={styles.usersSearchBar}>
              <input
                type={"text"}
                placeholder={"חיפוש"}
                className={styles.usersInput}
                onChange={onChangeValue}
                value={inputValue}
              />
              {usersCategory !== "therapist" && (
                <div className={styles.checkboxes}>
                  <input
                    name={"hosen"}
                    id={"hosen"}
                    type={"checkbox"}
                    onChange={() => {
                      setCheckedShowHosenUsers((prev) => !prev);
                    }}
                    checked={checkedShowHosenUsers}
                  />
                  <label htmlFor={"hosen"} className={styles.label}>
                    הצג משתמשי Hosen
                  </label>
                  <input
                    name={"hosenWithRecommendations"}
                    id={"hosenWithRecommendations"}
                    type={"checkbox"}
                    onChange={() => {
                      setCheckedShowHosenUsersWithRecommendations(
                        (prev) => !prev
                      );
                    }}
                    checked={checkedShowHosenUsersWithRecommendations}
                  />
                  <label
                    htmlFor={"hosenWithRecommendations"}
                    className={styles.label}
                  >
                    עם המלצה
                  </label>
                </div>
              )}
            </div>
            {userList && userList.length > 0 ? (
              <UsersList userList={userList} />
            ) : (
              <p className={styles.noUsers}>לא נמצאו משתמשים</p>
            )}
            <p className={styles.usersTotal}>
              סה"כ משתמשים:
              {usersCategory === "therapist"
                ? therapists.length
                : checkedShowHosenUsersWithRecommendations
                ? hosenUsersWithRecommendations.length
                : checkedShowHosenUsers
                ? hosenUsers?.length
                : users?.length || "0"}
            </p>
          </div>
        </article>
      ) : (
        <ul className={`options ${styles.options}`}>
          {userCategories.map((cat) => (
            <p
              key={cat.value}
              className={`option hover`}
              onClick={() => setUsersCategory(cat.value)}
            >
              {cat.label}
            </p>
          ))}
        </ul>
      )}

      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <Modal
          onClose={() => {
            setOpenSuccessModal(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenSuccessModal(false);
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default UsersPage;
