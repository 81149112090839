import styles from "./questionReport.module.css";
import {
  TQuestionnaire,
  TQuestionnaireQuestion,
} from "../../services/types/user.types";
function QuestionReport({
  report,
}: {
  report: { questionnaire: TQuestionnaire; results: TQuestionnaireQuestion[] };
}) {
  return (
    <ul className={`${styles.list} scroll scroll_thin scroll_rounded`}>
      <li className={` ${styles.li} ${styles.li_header}`}>
        <p className={styles.text}>שאלה</p>
        <p className={styles.text}>תשובה</p>
        <p className={styles.text}>{"ציון"}</p>
      </li>
      {report.results.length > 0 ? (
        report.results.map((q, index) => (
          <li key={index} className={styles.li}>
            <p className={styles.text}>{q.Desc}</p>
            <p className={styles.text}>{q.Answer || "-"}</p>
            <p className={styles.text}>{q.Score}</p>
          </li>
        ))
      ) : (
        <p className={styles.noResults}>לא נמצאו תוצאות</p>
      )}
    </ul>
  );
}

export default QuestionReport;
