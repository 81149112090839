import styles from "../forms.module.css";
import { TAddExerciseRequest } from "../../../services/types/utils.types";
import React, { ChangeEvent, FormEvent } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../loader/loader";
import { ActionMeta, SingleValue } from "react-select";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../../types/types";

interface IAddOrEditExerciseFormProps {
  form: TAddExerciseRequest;
  isLoading: boolean;
  onSubmit: (e: FormEvent) => void;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectChange: (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => void;
}
function AddOrEditExerciseForm({
  form,
  isLoading,
  onSubmit,
  handleChange,
  onSelectChange,
}: IAddOrEditExerciseFormProps) {
  return isLoading ? (
    <Loader />
  ) : (
    <form className={styles.form}>
      <ul
        className={`${styles.form__list} ${styles.form__list_type_threeColumns}`}
      >
        <li>
          <label htmlFor="Title" className={styles.form__label}>
            כותרת התרגיל
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Title"
            id="Title"
            value={form.Title}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="Symbol" className={styles.form__label}>
            סימן
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="Symbol"
            id="Symbol"
            value={form.Symbol}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="descrip" className={styles.form__label}>
            תיאור התרגיל
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="descrip"
            id="descrip"
            value={form.descrip}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          !form.Title && styles.form__btn_disabled
        } `}
        disabled={!form.Title}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : "ליצור תרגיל"}
      </button>
    </form>
  );
}

export default AddOrEditExerciseForm;
