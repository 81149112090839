import { TLabeledItem } from "../types/types";
import { SingleValue } from "react-select";
import { IQuestion } from "../services/types/utils.types";

interface IAction {
  type: string;
  payload: string | SingleValue<TLabeledItem> | IQuestion;
}
function isIQuestion(payload: any): payload is IQuestion {
  return (
    (payload as IQuestion).Id !== undefined &&
    payload.DescDataType !== undefined
  );
}

export default function addOrEditQuestionReducer(
  state: IQuestion,
  action: IAction
): IQuestion {
  const { type, payload } = action;
  if (isIQuestion(payload)) {
    return { ...state, ...payload };
  }
  if (typeof payload === "string") {
    console.log("string");
    return {
      ...state,
      [type]: payload,
    };
  } else {
    switch (type) {
      case "IsEnd":
        return {
          ...state,
          IsEnd: payload?.value !== "0",
        };
      case type: {
        return { ...state, [type]: payload?.value };
      }
      default:
        return state;
    }
  }
}
