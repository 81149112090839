import styles from "./questions.module.css";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import {
  useGetQuestionsDataQuery,
  useGetQuestionsQuery,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { questionsSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import { TLabeledItem } from "../../types/types";
import { useDebounce } from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { filterObjectsByString } from "../../utils/search";
import { IQuestion } from "../../services/types/utils.types";
import QuestionsList from "../../components/questionsList/questionsList";

function QuestionsPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const [inputValue, setInputValue] = useState<string>("");

  const { isLoading } = useGetQuestionsQuery();
  const { data, isLoading: isDataLoading } = useGetQuestionsDataQuery();

  const questions = useAppSelector(questionsSelector);
  const [questionsToShow, setQuestionsToShow] = useState(questions);
  const [questionnaireNames, setQuestionnaireNames] = useState<string[]>([]);

  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    setQuestionsToShow(questions);
  }, [questions]);

  useEffect(() => {
    if (data) {
      const { NameQuen } = data;
      setQuestionnaireNames(NameQuen);
    }
  }, [data]);

  const search = useCallback(async () => {
    const filteredQuestions = filterObjectsByString(debouncedValue, questions);
    setQuestionsToShow(filteredQuestions as IQuestion[]);
  }, [debouncedValue, questions]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const filterQuestionsByQuestionnaire = (value: SingleValue<TLabeledItem>) => {
    if (value) {
      const filteredQuestions = filterObjectsByString(value.value, questions);
      setQuestionsToShow(filteredQuestions as IQuestion[]);
    }
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <section className={styles.questionnaires}>
      <div className={styles.header}>
        <button
          type={"button"}
          className={styles.addBtn}
          onClick={() => navigate("add_new")}
        >
          {!mobile && "הוסף שאלה"}
          <span className={styles.plus}>+</span>
        </button>
        <input
          type={"text"}
          placeholder={"חפס..."}
          className={styles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
        <Select
          name={"NameQ"}
          options={[
            ...questionnaireNames.map((n) => ({ value: n, label: n })),
            { value: "", label: "הכל" },
          ]}
          placeholder={"אנא בחר שם השאלון"}
          className={styles.select}
          onChange={filterQuestionsByQuestionnaire}
        />
      </div>
      {isLoading || isDataLoading ? (
        <Loader />
      ) : questionsToShow.length > 0 ? (
        <QuestionsList questionsToShow={questionsToShow} />
      ) : (
        <p className={"text"}>לא נמצאו תוצאות</p>
      )}
    </section>
  );
}

export default QuestionsPage;
