import styles from "./addOrEditQuestion.module.css";
import stylesQuestionnaire from "../../../pages/questionnaires/questionnaires.module.css";
import Select, { SingleValue } from "react-select";
import {
  nextQuestionOptionsInitialState,
  statusOptions,
  typeDataOptions,
  yesNoOptions,
} from "../../../utils/constants";
import {
  IAddOrEditQuestionnaire,
  TLabeledItem,
  TLabeledItemWithId,
} from "../../../types/types";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAddNextQuestionIdToOptionMutation,
  useAddOptionMutation,
  useDeleteOptionMutation,
  useGetQuestionsDataQuery,
  useLazyGetQuestionOptionsQuery,
  useUpdateOptionMutation,
} from "../../../services/api/utils.api";
import Loader from "../../loader/loader";
import { TQuestionOption } from "../../../services/types/utils.types";
import { useAppSelector } from "../../../services/hooks";
import { questionsSelector } from "../../../services/selectors/utils.selectors";
import { findChanges, mergeArrays } from "../../../utils/utils";

function AddOrEditQuestionForm({
  form,
  handleChange,
  onSelectChange,
  isLoading,
  onSubmit,
  questionOptions,
}: IAddOrEditQuestionnaire) {
  const { id } = useParams();
  const questions = useAppSelector(questionsSelector).filter(
    (q) => q.DescQuestionnaire === form.DescQuestionnaire
  );
  console.log(form);

  const { data, isLoading: isDataLoading } = useGetQuestionsDataQuery();
  const [getQuestionOptions] = useLazyGetQuestionOptionsQuery();
  const [addOption] = useAddOptionMutation();
  const [deleteOption] = useDeleteOptionMutation();
  const [updateOptionDesc] = useUpdateOptionMutation();
  const [addNextQuestionIdToOption] = useAddNextQuestionIdToOptionMutation();
  const [questionnaireNames, setQuestionnaireNames] = useState<TLabeledItem[]>(
    []
  );
  const [nextQuestionOptions, setNextQuestionOptions] = useState<
    TLabeledItemWithId[]
  >([]);
  const [options, setOptions] = useState(questionOptions || []);
  console.log("options", options, "nextOp:", nextQuestionOptions);
  useEffect(() => {
    if (data) {
      const { NameQuen } = data;
      setQuestionnaireNames(NameQuen.map((n) => ({ value: n, label: n })));
    }
  }, [data]);
  useEffect(() => {
    const allQuestions = questions.filter(
      (q) => q.DescQuestionnaire === form.DescQuestionnaire
    );
    setNextQuestionOptions(
      allQuestions
        .filter((qst) => form?.Seq && form.Seq < qst.Seq)
        .map((qn) => ({ value: qn.Id.toString(), label: qn.Desc, Id: qn.Id }))
    );
  }, [form.DescQuestionnaire]);

  useEffect(() => {
    questionOptions && setOptions(mergeArrays(questionOptions, options)); //update rendered options on state options change
  }, [questionOptions]);

  useEffect(() => {
    if (form.Id && form.DescDataType === "OptionId") {
      getQuestionOptions(form.Id);
    }
  }, [form.Id, form.DescDataType, getQuestionOptions]);

  const handleOptionChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    o: TQuestionOption
  ) => {
    console.log(e.target, o, options, questionOptions);
    setOptions((prev) =>
      prev.map((op) => (op.Id === o.Id ? { ...op, Desc: e.target.value } : op))
    );
  };
  const onOptionChange = () => {
    if (questionOptions) {
      const { descChanged, nextQuestionIdChanged, nextquesChanged } =
        findChanges(questionOptions, options);
      console.log(descChanged, 1, nextQuestionIdChanged, nextquesChanged);
      if (descChanged.length > 0) {
        descChanged.forEach((o) =>
          updateOptionDesc({ id: o.Id.toString(), text: o.Desc })
        );
      }
    }
  };
  console.log(nextQuestionOptions);
  return isDataLoading ? (
    <Loader />
  ) : (
    <form className={styles.form}>
      <ul className={styles.form__list}>
        <li>
          <label htmlFor="Desc" className={styles.form__label}>
            שאלה
          </label>
          <textarea
            className={`${styles.form__input}`}
            name="Desc"
            id="Desc"
            value={form.Desc}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="DescQuestionnaire" className={styles.form__label}>
            שם השאלון
          </label>
          <Select
            name={"DescQuestionnaire"}
            options={questionnaireNames}
            placeholder={"אנא בחר שם השאלון"}
            // defaultValue={questionnaireTypes[0]}
            value={
              form.DescQuestionnaire
                ? questionnaireNames.find(
                    (n) => n.value === form.DescQuestionnaire
                  )
                : undefined
            }
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="StatusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"StatusId"}
            options={statusOptions}
            value={form.StatusId === 1 ? statusOptions[0] : statusOptions[1]}
            placeholder={"אנא בחרסוג סטטוס"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="IsEnd" className={styles.form__label}>
            אם אחרון
          </label>
          <Select
            name={"IsEnd"}
            options={yesNoOptions}
            defaultValue={yesNoOptions[1]}
            value={form.IsEnd ? yesNoOptions[1] : yesNoOptions[0]}
            placeholder={"אנא בחר"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="DescDataType" className={styles.form__label}>
            סוג התשובה
          </label>
          <Select
            name={"DescDataType"}
            options={typeDataOptions}
            value={
              form.DescDataType
                ? typeDataOptions.find((t) => t.value === form.DescDataType)
                : undefined
            }
            placeholder={"אנא בחר סוג התשובה"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="NextQuestionDesc" className={styles.form__label}>
            השאלה הבאה
          </label>
          <Select
            name={"NextQuestionDesc"}
            isDisabled={form.IsEnd}
            options={[
              ...nextQuestionOptionsInitialState,
              ...nextQuestionOptions,
            ]}
            value={
              form.NextQuestionDesc
                ? nextQuestionOptions.find(
                    (q) => q.label === form.NextQuestionDesc
                  )
                : nextQuestionOptionsInitialState[0]
            }
            placeholder={"אנא בחר"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
      </ul>
      {form.DescDataType === "OptionId" && (
        <>
          <h3 className={styles.form__title}>אופציות</h3>
          {questionOptions.length > 0 && (
            <ul
              className={`${styles.form__list} ${styles.form__list_type_options}`}
            >
              <>
                <li className={styles.form__option}>
                  <p
                    className={`${styles.form__noMargin} ${styles.form__bold}`}
                  >
                    מס':
                  </p>
                  <p
                    className={`${styles.form__noMargin} ${styles.form__bold}`}
                  >
                    אופציה:
                  </p>
                  <p
                    className={`${styles.form__noMargin} ${styles.form__bold}`}
                  >
                    שאלה הבאה:
                  </p>
                  <p
                    className={`${styles.form__noMargin} ${styles.form__bold}`}
                  >
                    אפשרויות:
                  </p>
                </li>
                {options.map((o) => (
                  <li key={o.Id} className={styles.form__option}>
                    <p className={styles.form__noMargin}>{o.Id}</p>
                    <textarea
                      className={`${styles.form__input}`}
                      name="Desc"
                      id="Desc"
                      value={o.Desc}
                      onChange={(e) => handleOptionChange(e, o)}
                    />
                    <Select
                      name={"Nextques"}
                      options={nextQuestionOptions}
                      placeholder={"בחר.."}
                      value={
                        nextQuestionOptions
                          ? nextQuestionOptions.find(
                              (opt) =>
                                o.NextQuestionId?.toString() === opt.value
                            )
                          : undefined
                      }
                      isDisabled={form.IsEnd || form.NextQuestionDesc !== null}
                      onChange={(value: SingleValue<TLabeledItem>) => {
                        const newId = Number(value?.value);
                        const questionId = Number(id);
                        questionId &&
                          newId &&
                          addNextQuestionIdToOption({
                            idOfOption: o.Id,
                            nextQusions: newId,
                            IdQuestion: questionId,
                          });
                      }}
                    />
                    <div className={stylesQuestionnaire.listCell}>
                      <button
                        type={"button"}
                        className={`${stylesQuestionnaire.btn} ${stylesQuestionnaire.btn_type_edit}`}
                        //   onClick={() =>
                        // navigate(`/protocols/questionnaires/edit/${q.Id}`)
                        // }
                      />
                      <button
                        type={"button"}
                        className={`${stylesQuestionnaire.btn} ${stylesQuestionnaire.btn_type_delete}`}
                        onClick={() => {
                          deleteOption(o.Id).then((response) => {
                            if ("data" in response && response.data) {
                              getQuestionOptions(Number(id));
                            }
                          });
                        }}
                      />
                    </div>
                  </li>
                ))}
              </>
            </ul>
          )}
          <button
            type={"button"}
            className={`${stylesQuestionnaire.addBtn} ${styles.form__btn} ${styles.form__btn_add}`}
            onClick={() => {
              if (id) {
                addOption({ id, text: "" }).then((response) => {
                  if ("data" in response && response.data) {
                    getQuestionOptions(Number(id));
                  }
                });
              }
            }}
          >
            הוסף אופציה
            <span className={styles.plus}>+</span>
          </button>
        </>
      )}
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          isLoading && styles.form__btn_disabled
        } `}
        disabled={isLoading}
        onClick={(e) => {
          e.preventDefault();
          onOptionChange();
          onSubmit(e);
        }}
      >
        {isLoading ? "שליחה" : id ? "לערוך שאלה " : "ליצור שאלה"}
      </button>
    </form>
  );
}

export default AddOrEditQuestionForm;
