import { TCategory, TQuestionOption } from "../services/types/utils.types";
import { TQuestionnaireEntry } from "../services/types/user.types";
import { IOption } from "../types/types";

export function compareArrays(
  array1: TQuestionOption[],
  array2: TQuestionOption[]
): TQuestionOption[] {
  const changes: TQuestionOption[] = [];

  array1.forEach((obj1) => {
    const obj2 = array2.find((o) => o.Id === obj1.Id);
    if (!obj2) return;

    if (
      obj1.Desc !== obj2.Desc ||
      obj1.GroupId !== obj2.GroupId ||
      obj1.NextQuestionId !== obj2.NextQuestionId ||
      obj1.Nextques !== obj2.Nextques
    ) {
      changes.push(obj2);
    }
  });

  return changes;
}
export function mergeArrays(
  array1: TQuestionOption[],
  array2: TQuestionOption[]
): TQuestionOption[] {
  return array1.map((obj1) => {
    const obj2 = array2.find((o) => o.Id === obj1.Id);
    return obj2 || obj1;
  });
}

export function findChanges(
  array1: TQuestionOption[],
  array2: TQuestionOption[]
): {
  descChanged: TQuestionOption[];
  groupIdChanged: TQuestionOption[];
  nextQuestionIdChanged: TQuestionOption[];
  nextquesChanged: TQuestionOption[];
} {
  const descChanged: TQuestionOption[] = [];
  const groupIdChanged: TQuestionOption[] = [];
  const nextQuestionIdChanged: TQuestionOption[] = [];
  const nextquesChanged: TQuestionOption[] = [];

  array1.forEach((obj1) => {
    const obj2 = array2.find((o) => o.Id === obj1.Id);
    if (!obj2) {
      return;
    }

    if (obj1.Desc !== obj2.Desc) {
      descChanged.push(obj2);
    }
    if (obj1.GroupId !== obj2.GroupId) {
      groupIdChanged.push(obj2);
    }
    if (obj1.NextQuestionId !== obj2.NextQuestionId) {
      nextQuestionIdChanged.push(obj2);
    }
    if (obj1.Nextques !== obj2.Nextques) {
      nextquesChanged.push(obj2);
    }
  });

  return {
    descChanged: descChanged,
    groupIdChanged: groupIdChanged,
    nextQuestionIdChanged: nextQuestionIdChanged,
    nextquesChanged: nextquesChanged,
  };
}

export const getCategory = (categories: TCategory[], id: number): string => {
  const category = categories.find((c) => c.Id === id);
  return category ? category.Name : "";
};

export const getTimeFromTimestamp = (date: Date | string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const timestamp = date.toTimeString().split(":");
  return `${timestamp[0]}:${timestamp[1]}`;
};

export function getPrintDate(date: Date, short?: boolean): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${
    !short ? date.getFullYear() : date.getFullYear().toString().substr(-2)
  }`;
}
export const formatDateOptions = (
  questionnaires: TQuestionnaireEntry[],
  questionnaireId: number
): IOption[] => {
  return questionnaires
    .filter((qu) => qu.QuestionnaireId === questionnaireId)
    .map((q) => ({
      label: `${getPrintDate(
        new Date(q.TimeStamp),
        true
      )} (${getTimeFromTimestamp(new Date(q.TimeStamp))}) (${q.Cycle})`,
      value: q.TimeStamp,
    }));
};
