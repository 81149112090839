import {
  IQuestion,
  TNameQuen,
  TQuestionOption,
} from "../services/types/utils.types";
import { ChangeEvent, FormEvent } from "react";
import { ActionMeta, SingleValue } from "react-select";

export enum Category {
  Main = "main",
  Users = "users",
  Library = "library",
  Support = "support",
  Forum = "forum",
  Protocols = "protocols",
}

export type TLabeledItem = {
  value: string;
  label: string;
};
export type TLabeledItemWithId = TLabeledItem & {
  Id: number;
};

export type TQuestionnaireTypeOption = TLabeledItemWithId & {
  Symbol: string;
  Name: string;
};
export type TAddNewQuestionnaireForm = {
  Desc: string;
  Symbol: string;
  StartQuestion: string;
  StatusId: string;
  Date: string | null;
  default: string;
  monthi: string;
  type: number | string;
  EndQuestion: string;
  periodicity?: TLabeledItem;
};

export interface IPopupWithoutProps {
  onClose: () => void;
}

export type TQuestionnaireType = TLabeledItem & {
  Id: number;
  Name: string;
  Symbol: string;
};

export type TAddQuestionForm = {
  DescQ: string;
  IsEnd: boolean;
  NameQ: string;
  StatusId: number;
  typeData: TNameQuen;
  DescDataType: TNameQuen;
};

export interface IAddOrEditQuestionnaire {
  form: Partial<IQuestion>;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectChange: (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => void;
  isLoading: boolean;
  onSubmit: (e: FormEvent) => void;
  questionOptions: TQuestionOption[];
}
export interface IUser {
  [key: string]: any;
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  account: string;
  avatar: string;
  email: string;
  phone: string;
  address: string;
  plan: string;
  // therapists: ITherapist[];
  // appointments: IAppointment[];
  // settings: ISettings;
  // prescriptions: IPrescription[];
  // invoices: IInvoice[];
  // videos: IVideo[];
  password: string; //remove when server is ready
  card?: string;
  unreadMessages: number;
  myDayText?: "";
  myTreatmentText?: "";
  passport?: string | null;
  country?: string | null;
  // messages?: IMessage[];
}

export type TUserForm = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birth: string;
  genderId: number;
  passport: string;
  [key: string]: any;
};

export interface IOption {
  value: string;
  label: string;
  desc?: string;
}
export type TOptionWithDesc = IOption & {
  desc: string;
};

export type TTotalScoreRRM = {
  TotalScore: number;
  RRM: number;
};
export type TGroup = {
  Id: number;
  Name: string;
  QuestionsId: number;
};
export type TReportQuestionOption = {
  Id: number;
  QuestionsId: number;
  GroupId: number | null;
  Groups: TGroup | null;
  Desc: string;
  NextQuestionId: number | null;
  NextQuestions: any | null; // Adjust the type of NextQuestions if you know the specific structure or replace 'any' with more specific type
  Seq: number;
  count?: number | null;
  min?: null | number;
};
