import popupStyles from "./privacyProtectionPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { ChangeEvent, useState } from "react";

function PrivacyProtectionPopup({ onClose }: IPopupWithoutProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>הצהרת הגנת הפרטיות</h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <p className={popupStyles.popup__text}>
        בהתאם לחוקי הגנת הפרטיות ולמדיניות האבטחה של המרפאה, אנו מחויבים לשמור
        על סודיות המידע הרפואי של המטופלים ולהבטיח כי הגישה למידע זה נעשית אך
        ורק באישורם המפורש של המטופל והמטפל האחראי.
      </p>
      <p className={popupStyles.popup__text}>
        אני מצהיר/ה בזאת כי הכניסה לצפייה במידע הרפואי הרגיש של המטופל נעשתה
        באישורו של המטופל ובאישורו של המטפל האחראי, ו/או כחלק מבדיקה של מצב
        חירום בתיק הרפואי ו/או כחלק מחובת דיווח על חשש למצב מסכן חיים למטופל
        ו/או לסביבתו, ו/או כחלק מבקשה לטיפול המשך ו/או כחלק מבקשה להערכת סיכון.
      </p>
      <p className={popupStyles.popup__text}>
        אנו מבינים את הרגישות והחשיבות של שמירת פרטיות המטופלים ומתחייבים לפעול
        בהתאם לכל ההנחיות והתקנות המחייבות בתחום זה.
      </p>
      <label className={popupStyles.popup__text} htmlFor={"confirm"}>
        <input
          id={"confirm"}
          name={"confirm"}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        אני מאשר/ת
      </label>
      <button
        className={`button hover ${popupStyles.popup__nextBtn} ${
          !isChecked && popupStyles.popup__nextBtn_disabled
        }`}
        onClick={onClose}
        disabled={!isChecked}
      >
        להמשיך
      </button>
    </article>
  );
}

export default PrivacyProtectionPopup;
