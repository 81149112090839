import {
  TAddNewQuestionnaireForm,
  TLabeledItem,
  TQuestionnaireTypeOption,
} from "../types/types";
import { SingleValue } from "react-select";

interface IAction {
  type: string;
  payload: string | SingleValue<TLabeledItem | TQuestionnaireTypeOption>;
}
function isTQuestionnaireTypeOption(
  payload: any
): payload is TQuestionnaireTypeOption {
  return (payload as TQuestionnaireTypeOption)?.Id !== undefined;
}

export default function addOrEditQuestionnaireReducer(
  state: TAddNewQuestionnaireForm,
  action: IAction
): TAddNewQuestionnaireForm {
  const { type, payload } = action;
  if (isTQuestionnaireTypeOption(payload)) {
    switch (type) {
      case "type":
        return {
          ...state,
          type: payload.Id,
        };
      default:
        return state;
    }
  } else if (typeof payload === "string") {
    switch (type) {
      case type:
        return {
          ...state,
          [type]: payload,
        };
      default:
        return state;
    }
  } else {
    switch (type) {
      case "StatusId":
        return {
          ...state,
          StatusId: payload?.value || "",
        };
      case "periodicity":
        return {
          ...state,
          periodicity: payload || undefined,
          Date: null,
          default: "",
          monthi: "",
        };
      default:
        return state;
    }
  }
}
