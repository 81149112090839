import styles from "./library.module.css";
import Select, { SingleValue } from "react-select";
import { searchLibraryOptions } from "../../utils/constants";
import Loader from "../../components/loader/loader";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { TLabeledItem } from "../../types/types";
import { useAppSelector } from "../../services/hooks";
import {
  categoriesSelector,
  exercisesSelector,
} from "../../services/selectors/utils.selectors";
import {
  useGetExercisesQuery,
  useSearchLibraryMutation,
  useGetLibraryCategoriesQuery,
  useAddCategoryMutation,
  useLazyGetLibraryCategoriesQuery,
} from "../../services/api/utils.api";
import CreatableSelect from "react-select/creatable";
import { getCategory } from "../../utils/utils";
import { useDebounce } from "../../hooks/useDebounce";
import { filterObjectsByString } from "../../utils/search";
import { IQuestion } from "../../services/types/utils.types";

function LibraryPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const { isLoading } = useGetExercisesQuery();
  useGetLibraryCategoriesQuery();

  const [selectValue, setSelectValue] = useState<TLabeledItem>(
    searchLibraryOptions[0]
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [featureToDeleteId, setFeatureToDeleteId] = useState("");

  const exercises = useAppSelector(exercisesSelector);
  const categories = useAppSelector(categoriesSelector);
  const [searchExercises, { isLoading: isSearchLoading }] =
    useSearchLibraryMutation();
  const [addCategory] = useAddCategoryMutation();
  const [refetchCategories] = useLazyGetLibraryCategoriesQuery();

  const [toggle, setToggle] = useState({});
  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const debouncedValue = useDebounce(inputValue, 500);
  const search = useCallback(async () => {
    searchExercises({
      val:
        selectValue.value === "Exercises.StatusId"
          ? debouncedValue.includes("לא")
            ? "0"
            : "1"
          : debouncedValue,
      ClumnSerch: selectValue.value,
    });
  }, [debouncedValue, searchExercises, selectValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);
  const onSelectChange = (value: SingleValue<TLabeledItem>) => {
    setSelectValue(value as TLabeledItem);
  };
  const onCategorySelectChange = (value: SingleValue<TLabeledItem>) => {
    console.log(value);
    console.log(exercises);
    value &&
      searchExercises({
        ClumnSerch: "ExercisesCategories.[Name]",
        val: value.value !== "all" ? value.label : "",
      });
  };

  const handleCreate = (inputValue: string) => {
    addCategory(inputValue).then(() => {
      refetchCategories();
    });
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <section className={styles.library}>
      <div className={styles.header}>
        <input
          type={"text"}
          placeholder={`חפס...`}
          className={styles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
        <Select
          name={"search"}
          options={searchLibraryOptions}
          placeholder={"אנא בחר..."}
          className={styles.select}
          defaultValue={searchLibraryOptions[0]}
          onChange={onSelectChange}
        />
        <CreatableSelect
          name={"categories"}
          options={[
            ...categories.map((c) => ({
              label: c.Name,
              value: c.Id.toString(),
            })),
            { value: "all", label: "הכל" },
          ]}
          placeholder={"קטגוריות"}
          className={styles.select}
          onChange={onCategorySelectChange}
          onCreateOption={handleCreate}
          formatCreateLabel={(inputText) => ` הוסף חדש: ${inputText} `}
        />
        <button
          type={"button"}
          className={styles.addBtn}
          onClick={() => navigate("add_new")}
        >
          {"הוסף תרגיל"}
          <span className={styles.plus}>+</span>
        </button>
      </div>
      {isLoading || isSearchLoading ? (
        <Loader />
      ) : exercises.length > 0 ? (
        mobile ? (
          <ul className={styles.list}>
            {exercises.map((e, index) => (
              <li
                key={e.Id}
                className={`listRow_mobile ${
                  toggle[index as keyof typeof setToggle] &&
                  "listRow_mobile_long"
                }`}
                onClick={() => toggleAnswer(index)}
              >
                <p className={styles.listCell}>{e.Title}</p>
                <p className={styles.listCell}>{e.About}</p>
                <p className={styles.listCell}>
                  {getCategory(categories, e.ExercisesCategoriesId)}
                </p>
                <p className={styles.listCell}>{e.Symbol}</p>
                <p className={styles.listCell}>
                  {e.StatusId === 0 ? "לא פעיל" : "פעיל"}
                </p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() => navigate(`/protocols/features/edit/${e.Id}`)}
                    // disabled={isDeleteLoading || isRefechLoading || isLoading}
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setFeatureToDeleteId(e.Id.toString());
                      setOpenModal(true);
                    }}
                    // disabled={isDeleteLoading || isRefechLoading || isLoading}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className={styles.list}>
            <li className={`${styles.listRow} ${styles.listRow_header}`}>
              <p className={styles.listCell}>מס`(id):</p>
              <p className={styles.listCell}>כותרת:</p>
              <p className={styles.listCell}>סימן:</p>
              <p className={styles.listCell}>תיאור:</p>
              <p className={styles.listCell}>קטוגריה:</p>
              <p className={styles.listCell}>סטטוס:</p>
              <p className={styles.listCell}>אפשרויות:</p>
            </li>
            {exercises.map((e, index) => (
              <li
                key={e.Id}
                className={`${styles.listRow} ${
                  index % 2 === 0 && styles.listRow_colored
                }`}
              >
                <p className={styles.listCell}>{e.Id}</p>
                <p className={styles.listCell}>{e.Title}</p>
                <p className={styles.listCell}>{e.Symbol}</p>
                <p className={styles.listCell}>{e.About}</p>
                <p className={styles.listCell}>
                  {getCategory(categories, e.ExercisesCategoriesId)}
                </p>
                <p className={styles.listCell}>
                  {e.StatusId === 0 ? "לא פעיל" : "פעיל"}
                </p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() => navigate(`/protocols/features/edit/${e.Id}`)}
                    // disabled={isDeleteLoading || isRefechLoading || isLoading}
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setFeatureToDeleteId(e.Id.toString());
                      setOpenModal(true);
                    }}
                    // disabled={isDeleteLoading || isRefechLoading || isLoading}
                  />
                </div>
              </li>
            ))}
          </ul>
        )
      ) : (
        <p className={styles.text}>לא נמצאו תוצאות</p>
      )}
      {/*{openModal && (*/}
      {/*  <Modal onClose={() => setOpenModal(false)}>*/}
      {/*    <DeleteConfirmation*/}
      {/*      onDelete={() => {*/}
      {/*        deleteFeature(featureToDeleteId).then(() => refechFeatures());*/}
      {/*      }}*/}
      {/*      onClose={() => setOpenModal(false)}*/}
      {/*    />*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </section>
  );
}

export default LibraryPage;
