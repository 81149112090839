import sidebarStyles from "./sidebar.module.css";
import { Category } from "../../types/types";
import React, { FC } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/token";
import { useAppSelector } from "../../services/hooks";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { mobileWidth } from "../../utils/constants";
import { authApi } from "../../services/api/auth.api";
import { adminSelector } from "../../services/selectors/user.selectors";
import { menuCategories } from "../../utils/categories";

interface ISidebar {
  changeCategory: (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    cat: Category
  ) => void;
  activeCategory: Category;
  toggleMenu: () => void;
  openMenu: boolean;
}

const TherapistSidebar: FC<ISidebar> = ({
  changeCategory,
  activeCategory,
  toggleMenu,
  openMenu,
}) => {
  const mobile = useMediaQuery(mobileWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const isChat = location.pathname === "/chat";
  const user = useAppSelector(adminSelector);
  const [logout] = authApi.useLogoutMutation();

  return (
    <section
      className={`${sidebarStyles.sidebar} ${
        isChat && !mobile && sidebarStyles.sidebar_chat
      } ${mobile && openMenu ? sidebarStyles.sidebar_open : ""}`}
      onClick={toggleMenu}
    >
      <div
        className={`${sidebarStyles.sidebar__content_visible} ${
          openMenu && !mobile
            ? ""
            : mobile
            ? sidebarStyles.sidebar_open
            : sidebarStyles.sidebar__content_small
        }`}
      >
        <button className={sidebarStyles.sidebar__arrow} />
        <div className={sidebarStyles.sidebar__logo} />
        <div className={sidebarStyles.sidebar__welcome}>
          <h2 className={sidebarStyles.sidebar__title}>
            {user.firstName ? user.firstName : "אורח"}
          </h2>
          <img
            className={sidebarStyles.sidebar__avatar}
            src={user.avatar ? user.avatar : avatarPlaceholder}
            alt="תמונת משתמש"
          />
        </div>
        <div className={sidebarStyles.scrollable}>
          <nav className={sidebarStyles.sidebar__pages}>
            <Link
              to={`/`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Main &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => {
                changeCategory(e, Category.Main);
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>מסך הבית</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_myDay}`}
              />
            </Link>
            <Link
              to={`/${Category.Protocols}`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Protocols &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => changeCategory(e, Category.Protocols)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>
                {menuCategories[Category.Protocols]}
              </p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_protocols}`}
              />
            </Link>
            <Link
              to={`/${Category.Users}`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Users &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => {
                changeCategory(e, Category.Users);
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>משתמשים</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_users}`}
              />
            </Link>
            <Link
              to={`/${Category.Library}`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Library &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => changeCategory(e, Category.Library)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>
                ספריית פריוונצר
              </p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_prevencher}`}
              />
            </Link>
            <Link
              to={`/${Category.Forum}`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Forum &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => changeCategory(e, Category.Forum)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>פורום</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_forum}`}
              />
            </Link>
            <Link
              to={`/${Category.Support}`}
              className={`${sidebarStyles.sidebar__page} ${
                activeCategory === Category.Support &&
                sidebarStyles.sidebar__page_active
              }`}
              onClick={(e) => changeCategory(e, Category.Support)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>
                {menuCategories[Category.Support]}
              </p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_support}`}
              />
            </Link>
          </nav>
          <ul className={sidebarStyles.sidebar__footer}>
            <li>
              <div
                className={sidebarStyles.sidebar__footerItem}
                onClick={(e) => {
                  e.stopPropagation();
                  logout(getCookie("accessToken") ?? "");
                  navigate("/");
                }}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>יציאה</p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_logout}`}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TherapistSidebar;
