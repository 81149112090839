import styles from "./rrmModel.module.css";
import { findMinAndMax } from "../../../utils/constants";
import { TRRMListEntry } from "../../../services/types/user.types";

function RrmModelPopup({
  score,
  RRMScore,
  scoreList,
}: {
  score: number;
  RRMScore: number;
  scoreList: TRRMListEntry[];
}) {
  const rrmItem = scoreList?.find((item) => item.Id === RRMScore);
  const { min, max } = findMinAndMax(scoreList);
  return (
    <section className={styles.section}>
      <h3 className={"title"}>אנליזה לגורם מטפל</h3>
      {scoreList && scoreList.length > 0 ? (
        <div className={styles.content}>
          <div className={styles.images}>
            <article className={styles.card}>
              <h3 className={styles.card__title}>G-RRM</h3>
              <ul className={styles.card__list}>
                <li className={`${styles.card__li} ${styles.card__li_title}`}>
                  <span className={styles.card__text}>תמרור</span>
                  <span className={styles.card__text}>טווח</span>
                  <span className={styles.card__text}>קוד קבוצה</span>
                </li>
                {scoreList.map((item) => (
                  <li
                    className={`${styles.card__li} ${
                      item.Id === RRMScore && styles.card__li_active
                    }`}
                    key={item.Id}
                    style={
                      item.Id === RRMScore
                        ? { border: "2px solid #2c2b2b" }
                        : {}
                    }
                  >
                    <div
                      className={styles.card__cell}
                      style={{ backgroundColor: item.Color }}
                    >
                      {item.Desc || ""}
                    </div>
                    <span className={styles.card__text}>
                      {item.Max} - {item.Min}
                    </span>
                    <span className={styles.card__text}>{item.Title}</span>
                  </li>
                ))}
              </ul>
              {rrmItem && (
                <div className={styles.card__footer}>
                  <p className={styles.card__text}>SCORE={score}</p>
                  <p className={styles.card__text}>MIN={min}</p>
                  <p className={styles.card__text}>GROUP RRM={rrmItem.Title}</p>
                  <p className={styles.card__text}>MAX={max}</p>
                </div>
              )}
            </article>
          </div>
          <div className={styles.texts}>
            <h3
              className={`${styles.card__title} ${styles.card__title_type_text}`}
            >
              אנליזה
            </h3>
            <p className={styles.text}>{rrmItem?.Anliza}</p>
            <h3
              className={`${styles.card__title} ${styles.card__title_type_text}`}
            >
              המלצות התערבות
            </h3>
            <p className={styles.text}>{rrmItem?.Recommendations}</p>
            <h3
              className={`${styles.card__title} ${styles.card__title_type_text}`}
            >
              דגשים חשובים נוספים
            </h3>
            <p className={styles.text}>{rrmItem?.Highlights}</p>
            <h3
              className={`${styles.card__title} ${styles.card__title_type_text}`}
            >
              במהלך השיחה
            </h3>
            <p className={styles.text}>{rrmItem?.Conversation}</p>
          </div>
        </div>
      ) : (
        <p className={"text"}>לא נמצאו נתונים</p>
      )}
    </section>
  );
}

export default RrmModelPopup;
